import { DataService } from './../services/data.service';
import { observable, action, } from "mobx";
import { Injectable } from "@angular/core";
import { MappingService, pushToObject } from 'src/app/shared/services/mapping.service';
import { ConvertService } from 'src/app/shared/services/convert.service';
import { IAcademicYear } from '../interfaces/admission';

@Injectable({ providedIn: 'root' })
export class Environment {
  @observable user = null;
  @observable config = null;
  @observable academicEnv = null;
  @observable loading = false;
  @observable academic = null;
  @observable process = false;

  @observable campusPrograms: any[] = [];
  @observable abc = null;
  @observable abcFee = null;
  @observable public term = null;
  @observable public campus = null;
  @observable public programs = null;
  @observable public institute = null;
  @observable public allPrograms = [];
  @observable public puc_generation = null;
  @observable public currentTerm = null;

  @observable dailyShift = null;
  @observable sameDay = false;
  @observable balance = null;

  @observable public selectedTerm = null;
  @observable public selectedYear = null;

  @observable selectedCampus: any = null;
  @observable selectedSchool: any = null;
  @observable userRole: any = null;

  constructor(
    private ds: DataService,
  ) { }

  @action
  async fetchTermEnvironment(callback?: (term: IAcademicYear) => any) {
    const doc = await this.ds.academicRef().get().toPromise();
    if (doc.exists) {
      const { term } = pushToObject(doc);
      // this.selectedTerm = term;
      return callback && callback(term);
    }
  }

  @action
  fetchSysProgram(callback) {
    this.process = true;
    this.ds.sysProgramRef().valueChanges().subscribe(docs => {
      this.programs = docs;
      callback(docs)
      this.process = false;
    })
  }

  @observable fetchCampusProgramRef: any = null;
  @action
  fetchCampusProgram(campusKey, callback?: any) {
    this.fetchCampusProgramRef = this.ds.campusRef().doc(campusKey)
      .collection("training_programs", ref => ref.orderBy("name"))
      .valueChanges()
      .subscribe(docs => {
        this.campusPrograms = docs;
        if (callback) callback(docs)
      })
  }

  @action
  async fetchEnglishTerm(callback) {
    const termDoc = await this.ds.termAcademicRef().doc("ujj1QMuVpxU0GDMgT1QN").get().toPromise();
    const termData = MappingService.pushToObject(termDoc);
    callback(termData);
  }

  @action
  fetchEnvironmentToArray(callback) {
    this.ds.academicEnvironmentFireDocRef().get().then(snapshot => {
      const doc: any = { ...snapshot.data() };
      const { term } = doc;
      // this.currentTerm = term;
      // this.selectedTerm = term;
      this.config = doc;
      callback(doc);
    });
  }

  @action
  async fetchCurrentTermInstituteToObject(instituteKey, callback) {
    const instituteDoc = await this.ds.instituteRef().doc(instituteKey).get().toPromise();
    this.institute = MappingService.pushToObject(instituteDoc)
    const termDoc = await this.ds.paymentTermRef(instituteKey).get().toPromise();
    // this.selectedTerm = MappingService.pushToObject(termDoc);
    callback(this.selectedTerm)
  }

  @action
  fetchAcademicConfig() {
    // this.ds.academicRef().valueChanges().subscribe(doc => {
    //   const { abc_course, abc_course_fee, term, puc_generation } = doc;
    //   this.abc = abc_course;
    //   this.abcFee = abc_course_fee;
    //   this.term = term;
    //   this.puc_generation = puc_generation;
    //   this.academic = doc;
    // });
  }

  @action
  fetchAcademicConfigToArray(callback) {
    // this.ds.academicRef().valueChanges().subscribe(doc => {
    //   const { abc_course, abc_course_fee, term, puc_generation } = doc;
    //   this.abc = abc_course;
    //   this.abcFee = abc_course_fee;
    //   this.term = term;
    //   this.puc_generation = puc_generation;
    //   this.academic = doc;
    //   callback(doc);
    // });
  }

  @action
  fetchAllSysProgram(callback) {
    this.loading = true;
    this.ds.sysProgramRef().valueChanges().subscribe(docs => {
      this.programs = docs;
      this.ds.instituteRef().valueChanges().subscribe(req => {
        this.institute = req;
        this.allPrograms = [
          {
            'key': 'all',
            'name': 'All',
          }
        ]
        if (this.programs)
          this.allPrograms = this.allPrograms.concat(this.programs);
        if (this.institute)
          this.allPrograms = this.allPrograms.concat(this.institute);
        callback(this.allPrograms)
        this.loading = false;
      })
    })
  }

  @action
  async fetchUser(uid) {
    this.loading = true;
    this.dailyShift = null;
    this.sameDay = false;
    const userDoc = await this.ds.userRef(uid).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);
    this.dailyShift = ConvertService.toNull(this.user.shift);

    const configDoc = await this.ds.sysSetting().get().toPromise();
    const sysConfigDoc = await this.ds.academicRef().get().toPromise();
    this.config = MappingService.pushToObject(configDoc);
    this.academicEnv = MappingService.pushToObject(sysConfigDoc);
    this.loading = false;
  }

  @observable getUserRef: any = null;
  @action
  async getUser(callback) {
    this.loading = true;
    const userData: any = JSON.parse(localStorage.getItem('user'));
    const key = userData.key;
    this.getUserRef = this.ds.userRef(key).valueChanges().subscribe(async doc => {
      const sysConfigDoc = await this.ds.academicRef().get().toPromise();
      this.academicEnv = MappingService.pushToObject(sysConfigDoc);
      // const { term } = this.academicEnv;
      // this.selectedTerm = term;
      this.user = doc;
      this.loading = false;
      callback(doc)
    });
  }

  @action
  clearStore() {
    this.user = null;
    this.dailyShift = null;
    this.config = null;
    this.loading = false;
    this.academic = null;
    this.process = false;
    this.balance = null;
    this.abc = null;
    this.abcFee = null;
    this.term = null;
    this.campus = null;
    this.puc_generation = null;
  }

  @action
  async fetchDailyShift(cashierKey: string) {
    this.loading = true;
    this.dailyShift = null;
    this.sameDay = false;
    const userDoc = await this.ds.userRef(cashierKey).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);
    if (this.user && this.user.shift) {
      const shiftDoc = await this.ds.shiftMovementDBRef(this.user.shift.key).get().toPromise();
      this.dailyShift = MappingService.pushToObject(shiftDoc);
      const { create_date_key } = this.dailyShift;
      const dayKey = ConvertService.dateKey();
      if (dayKey == create_date_key) this.sameDay = true;
    }

    this.loading = false;
  }

  @action
  async fetchConfigDoc() {
    const configDoc = await this.ds.sysSetting().get().toPromise();
    this.config = MappingService.pushToObject(configDoc);
  }

  @action
  async fetchEnvDoc() {
    const userData: any = JSON.parse(localStorage.getItem('user'));
    const schoolKey = userData.schoolKey;

    const sysConfigDoc = await this.ds.storeDocRef(schoolKey).collection("academic_environment").doc("academic_environment").get().toPromise();
    this.academicEnv = MappingService.pushToObject(sysConfigDoc);
  }

  @action
  async fetchUserDoc() {
    const userData: any = JSON.parse(localStorage.getItem('user'));
    const key = userData.key;
    const userDoc: any = await this.ds.userDocRef(key).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);

    this.selectedCampus = this.user.campus;
    this.selectedSchool = this.user.school;
    this.userRole = this.user.memberOf;
  }
  
  @action
  async fetchUserDocCallback(callback) {
    const userData: any = JSON.parse(localStorage.getItem('user'));
    const key = userData.key;
    const userDoc: any = await this.ds.userDocRef(key).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);

    this.selectedCampus = this.user.campus;
    this.selectedSchool = this.user.school;
    this.userRole = this.user.memberOf;
  }

  @action
  async fetchUserAccount(callback) {
    this.loading = true;
    this.sameDay = false;
    const userData: any = JSON.parse(localStorage.getItem('user'));

    const key = userData.key;
    const userDoc = await this.ds.userDocRef(key).get().toPromise();
    this.user = MappingService.pushToObject(userDoc);

    if (this.user && this.user.shift) {
      const shiftDoc = await this.ds.shiftMovementDBRef(this.user.shift.key).get().toPromise();
      this.dailyShift = MappingService.pushToObject(shiftDoc);
      const { create_date_key } = this.dailyShift;
      const dayKey = ConvertService.dateKey();
      if (dayKey == create_date_key) this.sameDay = true;
    }

    const settingDoc = await this.ds.settingDBFireStore().get().toPromise();
    const setting = MappingService.pushToObject(settingDoc);
    this.config = setting;

    // if (!userDoc.exists) {
    //   await this.authFire.signOut().then(() => {
    //     this.loading = false;
    //     this.router.navigate(['/auth']);
    //   })
    //   return;
    // }
    // const { apps } = this.user;
    // if (!apps || apps.filter(m => m === appObjects.finance.key).length === 0) {
    //   await this.authFire.signOut().then(() => {
    //     this.loading = false;
    //     this.router.navigate(['/auth']);
    //   })
    //   return;
    // }
    callback(this.user)
  }

  @action
  async fetchConfig() {
    const configDocs = await this.ds.sysSetting().get().toPromise();
    this.config = MappingService.pushToObject(configDocs);
  }

  @action
  async fetchConfigToArray(callback) {
    const configDocs = await this.ds.sysSetting().get().toPromise();
    this.config = MappingService.pushToObject(configDocs);
    callback(this.config)
  }

  @action
  getUserCallback(callback) {
    this.loading = true;
    this.user = null;
    const userData: any = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      this.ds.userRef(userData.key).valueChanges().subscribe(doc => {
        this.loading = false;
        this.user = doc;
        callback(this.user)
      });
    }
    callback(this.user)
  }

}
