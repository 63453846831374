import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Router } from '@angular/router';
import { Environment } from 'src/app/core/stores/environment';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/shared/components/change-password/change-password.component';
import { SCHOOL } from 'src/app/core/dummy/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toJS } from 'mobx';

@Component({
  selector: 'dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss']
})
export class DashboardNavComponent implements OnInit {
  @Input() user: any;
  @Input() term: any;
  @Input() campus: Array<any>;
  school = SCHOOL;
  constructor(
    public router: Router,
    public store: Environment,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public auth: AuthStore,) { }

  ngOnInit() {
  }

  _signOut() {
    this.auth.signOut();
  }

  toggleSidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-switch-menu");
  }

  changePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: null,
      panelClass: 'item-list-panel',
      width: '35vw',
      height: '100vh',
      disableClose: false,
      role: 'dialog',
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  LinkGoogleAuthPassword() {
    // this.auth.LinkGoogleAuthPassword();
    this.auth.LinkGoogleAuthPassword(success => {
      if (success) {
        this.snackBar.open("Link account with google successfully.", "Done", { duration: 3000 });
      }
    })
  }

  // unLinkGoogleAuthProvider() {
  //   this.auth.unLinkGoogleAuthProvider(success => {
  //     if (success) {
  //       this.snackBar.open("Unlink account with google successfully.", "Done", { duration: 3000 });
  //     }
  //   })
  // }
}
