<nav class="nav-header">
  <div class="app-container">
    <div class="brand-name" [routerLink]="['/']">
      <img src="../../../assets/images/logo.png" />
      <span class="switch-campus" *ngIf="campusList?.length > 0">
        {{"name" | getSchoolConfig}}
        <ng-container *ngIf="!store?.isAdmin">
          - {{store?.user?.campus?.name}}
        </ng-container>
        <ng-container *ngIf="store?.isAdmin">
          <button class="sc-b" [matMenuTriggerFor]="menu">
            <div class="sc-b-n">
              <span *ngIf="store?.user?.campus?.name">- {{store?.user?.campus?.name}}</span>
              <i class="material-icons c-icon" *ngIf="store?.user?.campus?.name">arrow_drop_down</i>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let item of campusList;">
              <button (click)="onSwitchCampus(item)" mat-menu-item>{{item?.name}}</button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </span>
    </div>
    <div class="anchor-nav">

    </div>
    <button (click)="closeShift()" mat-button *ngIf="store?.isOpen"
      class="apps-outline">{{store?.balance|currency}}</button>

    <button mat-button mat-icon-button [matMenuTriggerFor]="menumsg" class="cs-mat-menu-item">
      <mat-icon>mail</mat-icon>
    </button>

    <mat-menu #menumsg="matMenu" class="cs-matmenu-content" backdropClass="cs-matmenu-backdrop">
      <div class="mat-menu-bar" (click)="$event.stopPropagation();">
        <h3 class="m-title">Messages</h3>

      </div>
      <div class="mat-menu-body">

        <p class="text-center p-4">No Messages</p>
      </div>
    </mat-menu>

    <button mat-button mat-icon-button [matMenuTriggerFor]="menunoti" class="cs-mat-menu-item">
      <mat-icon>notifications</mat-icon>
    </button>

    <mat-menu #menunoti="matMenu" class="cs-matmenu-content" backdropClass="cs-matmenu-backdrop">
      <div class="mat-menu-bar" (click)="$event.stopPropagation();">
        <h3 class="m-title">Notifications</h3>
        <button mat-icon-button>
          <mat-icon aria-label="settings">settings</mat-icon>
        </button>
      </div>
      <div class="mat-menu-body">

        <p class="text-center p-4">No Notifications</p>

      </div>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="menuuser" class="cs-mat-menu-item" style="margin-right:0 !important">
      <div class="click-avatar">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c"
          alt="Auxswot">
      </div>
    </button>
    <mat-menu #menuuser="matMenu" class="cs-matmenu-content auto user-menu-content" backdropClass="cs-matmenu-backdrop">
      <div class="mat-menu-bar" (click)="$event.stopPropagation();">
        <div class="d-flex">
          <div class="mat-user-avatar">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sysanotta.appspot.com/o/no_image.svg?alt=media&token=f25af902-6c62-47e6-bdf3-48e3b0c6fe8c"
              alt="Auxswot">
          </div>
          <div class="mat-user-info">
            <h3>{{env?.user?.displayName}}</h3>
            <p>{{env?.user?.email}}</p>
          </div>
        </div>
      </div>
      <div class="mat-menu-body">
        <mat-list>
          <mat-list-item (click)="logOut()">
            <mat-icon mat-list-icon class="app-icon black">input</mat-icon>
            <div mat-line class="d-flex">
              Sign Out
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-menu>
  </div>
</nav>
