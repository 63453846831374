export const PettyCash_STATUS = {
  pending: { key: 1, text: 'Pending' },
  approved: { key: 2, text: 'Approved' },
  canceled: { key: 3, text: 'Canceled' },
}

export const instituteNoScheduleObj = {
  fail: { key: 1, text: "Fail" },
  drop: { key: 2, text: "Drop" },
  coming_back: { key: 3, text: "Coming Back" },
  change_program: { key: 4, text: "Change Program" },
};
export const scheduleModuleObj = {
  academic_schedule: { key: 'academic_schedule', text: 'Academic schedule', order: 1, icon: 'how_to_vote' },
  freshman_schedule: { key: 'freshman_schedule', text: 'Admission schedule', order: 2, icon: 'face' },
  accelerated_schedule: { key: 'accelerated_schedule', text: 'Accelerated schedule', order: 3, icon: 'layers' },
  abc_schedule: { key: 'abc_schedule', text: 'ABC class schedule', order: 4, icon: 'flag' },
  short_course_schedule: { key: 'short_course_schedule', text: 'Short course schedule', order: 5, icon: 'favorite' },
  institute: { key: 'institute', text: 'Institute and Center', order: 6, icon: 'school' },
}
export const academicProgram = [
  { key: 'associate', routerId: 'associate', active: true, icon: 'supervised_user_circle', name: 'Associate', order: 0, text: 'Associate', level: 'Undergraduate', type: 'ACADEMIC' },
  { key: 'bachelor', routerId: 'bachelor', active: true, icon: 'supervised_user_circle', name: 'Bachelor', order: 1, text: 'Bachelor', level: 'Undergraduate', type: 'ACADEMIC' },
  { key: 'pre_master', routerId: 'pre_master', active: true, icon: 'supervised_user_circle', name: 'Pre Master', order: 2, text: 'Pre Master', level: 'Pre Master', type: 'ACADEMIC' },
  { key: 'master', routerId: 'master', active: true, icon: 'supervised_user_circle', name: 'Master', order: 3, text: 'Master', level: 'Graduate', type: 'ACADEMIC' },
  { key: 'postgraduate', routerId: 'postgraduate', active: true, icon: 'supervised_user_circle', name: 'Doctor or PhD', order: 4, text: 'Doctor or PhD', level: 'Graduate', type: 'ACADEMIC' },
]

export const DISPLAY_STATUS_OBJECT = {
  'ACTIVE': { key: 1, text: 'Active' },
  'DISABLED': { key: 2, text: 'Disabled' },
}

export const STATUS_OBJECT = {
  'draft': { key: 1, text: 'Draft', router: 'draft' },
  'submitted': { key: 2, text: 'Submitted', router: 'submitted' },
  'deleted': { key: 3, text: 'Deleted', router: 'deleted' },
}
export const DISPLAY_STATUS = [
  { key: 0, text: 'All' },
  { key: 1, text: 'Active' },
  { key: 2, text: 'Disabled' },
]
export const ACADEMIC_YEAR_STATUS = [
  { key: 1, text: 'Hold' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
];
export const emStatus = [
  { key: 1, text: 'Active' },
  { key: 2, text: 'Inactive' },
  { key: 3, text: 'Terminate' },
  { key: 4, text: 'Resign' },
]
export const emStatusCompare = [
  { key: 1, text: 'active' },
  { key: 2, text: 'inactive' },
  { key: 3, text: 'terminate' },
  { key: 4, text: 'resign' },
]

export const SESSION_SHOW_STATUS = [
  { key: 1, text: 'Primary' },
  { key: 2, text: 'Secondary' },
]

export const AllowID = [
  { key: 1, text: 'No' },
  { key: 2, text: 'Yes' },
]

export const WorkingTypes = [
  { key: '1', text: "Full-time" },
  { key: '2', text: "Part-time" },
];

export const PromotiionType = [
  { key: 1, text: 'Promoted' },
  { key: 2, text: 'Assigned' },
]
export const Sector = [
  { key: 1, text: 'Private Sector' },
  { key: 2, text: 'Government' },
  { key: 3, text: 'NGO' },
  { key: 4, text: 'Government' },
  { key: 5, text: 'Other' },
]

export const EmployeeTypes = [
  { key: 1, text: "Khmer" },
  { key: 2, text: "Foreign" },
];

export const EmploymentStatus = [
  { key: 1, text: "Full Time Contract" },
  { key: 2, text: "Full Time Internship" },
  { key: 3, text: "Full Time Permanent" },
  { key: 4, text: "Part Time Contract" },
  { key: 5, text: "Part Time Internship" },
  { key: 6, text: "Part Time Permanent" },
];
export const MaritalStatus = [
  { key: 1, text: "Single" },
  { key: 2, text: "Married" },
  { key: 3, text: 'Divorced' },

];
export const titles = [
  { key: 1, text: "Prof." },
  { key: 2, text: "Dr." },
  { key: 3, text: "Mr." },
  { key: 4, text: "Mis." },
  { key: 5, text: "Ms." },
  { key: 6, text: "Mis." }
];
export const subjectTypes = [
  { key: 1, text: 'Foundation Course' },
  { key: 2, text: 'Directed Course' },
  { key: 3, text: 'Oriented Course' },
  { key: 4, text: 'Functional Skills Course' },
  { key: 5, text: 'Institutional Skills Course' },
  { key: 6, text: 'Basic Major Course' },
  { key: 7, text: 'Major Course' },
  { key: 8, text: 'Elective Course' },
  { key: 9, text: 'Graduation Path' },
  { key: 10, text: 'Other' },
]


export const DiplomaType = [
  { key: 1, text: 'NA' },
  { key: 2, text: 'High School Diploma' },
  { key: 3, text: 'Training Certificate' },
  { key: 4, text: 'AA' },
  { key: 5, text: 'BA' },
  { key: 6, text: 'BS' },
  { key: 7, text: 'LLB' },
  { key: 8, text: 'MA' },
  { key: 9, text: 'MBA' },
  { key: 10, text: 'MSc' },
  { key: 11, text: 'DBA' },
  { key: 12, text: 'PhD' },
]
export const EducationLevel = [
  { key: 1, text: 'NA' },
  { key: 2, text: 'Informal Training' },
  { key: 3, text: 'High School' },
  { key: 4, text: 'Vocational Training' },
  { key: 5, text: 'Associate Degree' },
  { key: 6, text: 'Bachelor' },
  { key: 7, text: 'Master' },
  { key: 8, text: 'Doctor PhD' },
]
export const enrollCourseStatus = {
  drop: { key: 1, text: 'Drop' },
  quit: { key: 2, text: 'Quit' },
}
export const SCHOOL_PROGRAM = [
  { key: 1, text: 'Nursery', },
  { key: 2, text: 'Kindergarten' },
  { key: 3, text: 'Primary School' },
  { key: 4, text: 'Secondary School' },
  { key: 5, text: 'High school' },
];
export const MONTHS_LIST = [
  { key: 1, text: '1 Month' },
  { key: 2, text: '2 Months' },
  { key: 3, text: '3 Months' },
  { key: 4, text: '4 Months' },
  { key: 5, text: '5 Months' },
  { key: 6, text: '6 Months' },
  { key: 7, text: '7 Months' },
  { key: 8, text: '8 Months' },
  { key: 9, text: '9 Months' },
  { key: 10, text: '10 Months' },
  { key: 11, text: '11 Months' },
  { key: 12, text: '12 Months' },
];
export const classroomFilterBy = [
  { key: "full_name", text: "Name" },
  { key: "mobile_phone", text: "Phone" },
  { key: "code", text: "Code" },
];
export const enrollStatusObj = {
  pending: { key: 1, text: "Pending" },
  add: { key: 2, text: "Add" },
  change: { key: 3, text: "Changed" },
  drop: { key: 4, text: "Drop" },
  quit: { key: 5, text: "Quit" },
};
export const PROGRAM_OPTION_OBJ = {
  yearBase: { key: 1, text: 'Year Base' },
  termBase: { key: 2, text: 'Term Base' },
}
export const appObjects = {
  cashier: { key: 'cashier', text: "Cashier" },
  enrollment: { key: 'class_manager', text: "Class Manager" },
  testing: { key: 'testing_center', text: "Testing Center" },
  scholarship: { key: 'scholarship', text: "Scholarship" },
  finance: { key: 'finance', text: "Finance" },
  reporting: { key: 'reporting', text: "Reporting" },
};

export const appArray = [
  { key: 'cashier', text: "Cashier" },
  { key: 'class_manager', text: "Class Manager" },
  { key: 'testing_center', text: "Testing Center" },
  { key: 'scholarship', text: "Scholarship" },
  { key: 'finance', text: "Finance" },
  { key: 'reporting_app', text: "Reporting App" },
]

export const memberOf = [
  { key: 0, text: "Administrator" },
  { key: 1, text: "Branch Manager" },
  { key: 2, text: "Guest" },
];

export const memberOfObj = {
  administrator: { key: 0, text: "Administrator" },
  branch_manager: { key: 1, text: "Branch Manager" },
  guest: { key: 2, text: "Guest" },
};

export const REPORT_ORDER = [
  { key: '1', text: 'Invoice No', orderString: 'invoice_no' },
  { key: '2', text: 'Payment Term', orderString: 'payment_term.key' },
  { key: '3', text: 'Cashier', orderString: 'received_by.key' },
  { key: '4', text: 'Campus', orderString: 'received_by.campus.key' },
];

export const Genders = [
  { key: 1, text: 'Male' },
  { key: 2, text: 'Female' },
  { key: 3, text: 'Monk' },
];

export const TermStatus = [
  { key: 1, text: 'Pending' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
];

export const batchStatusObj = {
  active: { key: 1, text: 'Active' },
  disable: { key: 2, text: 'Disable' },
  close: { key: 3, text: 'Close' },
}

export const Scholarships = {
  Approval: { key: 0, text: "Approval" },
  Reject: { key: 1, text: "Reject" },
  Pending: { key: 2, text: "Pending" }
};
export const daysOfWeek = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};
export const ShiftStatusObj = {
  opening: { key: 1, name: 'Opening' },
  closed: { key: 2, name: 'Closed' },
}

export const Status = [
  { key: 1, text: 'Pending' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
]
export const programTypesList = [
  { key: 0, text: 'All' },
  { key: 1, text: 'Week Day' },
  { key: 2, text: 'Weekend' },
  { key: 3, text: 'WD/WK' },
]
export const programsObj = {
  undergraduate: 1,
  graduate: 2,
  postgraduate: 3,
  association: 4,
  short_course: 5,
  others: 6,
  abc_course: 7,
  testing_fee: 8,
  spel_fee: 9,
  english_program: 10,
  psis_program: 11,
}
export const programs = [
  { key: 1, text: 'Undergraduate' },
  { key: 2, text: 'Graduate' },
  { key: 3, text: 'Postgraduate' },
  { key: 4, text: 'Association' },
  { key: 5, text: 'Short Course' },
  { key: 6, text: 'Miscellaneous', name: 'Miscellaneous' },
  { key: 7, text: 'ABC Course' },
  { key: 10, text: 'English Program' },
  { key: 11, text: 'Japanese Program' },
]
export const programTypes = [
  { key: 1, text: 'Week Day' },
  { key: 2, text: 'Weekend' },
  { key: 3, text: 'Executive' },
]
export const feeStatus = [
  { key: 1, text: 'Active' },
  { key: 2, text: 'Disabled' },
]

export const paymentType = {
  cash: { key: 1, text: 'Cash' },
  wingOnline: { key: 2, text: 'Wing Online', note: "PAY WITH WING ONLINE" },
  wingMerchant: { key: 3, text: 'Wing Merchant', note: "PAY WITH WING MERCHANT PAYMENT" },
  abaBilling: { key: 4, text: 'ABA Billing', note: "PAY WITH ABA BILL PAYMENT" },
  acledaBilling: { key: 5, text: 'ACLEDA Billing', note: "PAY WITH ACLEDA BILL PAYMENT" },
}

export const InvoiceTypes = [
  { key: 1, text: "Testing" },
  { key: 2, text: "Tuition Fee" },
  { key: 3, text: "Short Course" },
  { key: 4, text: "Academic Bridging Course" },
  { key: 5, text: "SPEL" },
  { key: 6, text: "Miscellaneous" },
  { key: 7, text: "Scholarship Penalty" },
];

export const invoiceTypesObj = {
  testing: { key: 1, text: "Testing" },
  tuitionFee: { key: 2, text: "Tuition Fee" },
  shortCourse: { key: 3, text: "Short Course" },
  abcCourse: { key: 4, text: "Academic Bridging Course" },
  spel: { key: 5, text: "SPEL" },
  miscellaneous: { key: 6, text: "Miscellaneous" },
  scholarshipPenalty: { key: 7, text: "Scholarship Penalty" },
  registrationFee: { key: 8, text: "Registration Fee" },
  admissionFee: { key: 9, text: "Admission Fee" },
  otherFee: { key: 10, text: "Other Fee" },
};

export const invoiceTypeParams = {
  testing: { key: 1, text: "Testing" },
  tuition_fee: { key: 2, text: "Tuition Fee" },
  academic_program: { key: 2, text: "Academic Tuition Fee" },
  institute_and_center: { key: 2, text: "Institute Tuition Fee" },
  short_course: { key: 3, text: "Short Course" },
  abc: { key: 4, text: "Academic Bridging Course" },
  miscellaneous: { key: 6, text: "Miscellaneous" },
  scholarshipPenalty: { key: 7, text: "Scholarship Penalty" },
  registrationFee: { key: 8, text: "Registration Fee" },
  admissionFee: { key: 9, text: "Admission Fee" },
  otherFee: { key: 10, text: "Other Fee" },
};

export const TestingStatus = {
  active: { key: 1, text: 'Active' },
  disables: { key: 2, text: 'Disabled' },
  expired: { key: 3, text: 'Expired' },
}

export const studentStatus = {
  activePsis: { key: "psis", active: true, firstPayment: false },
  activeFreshPsis: { key: "psis", active: true, firstPayment: true },
  disablePsis: { key: "psis", active: false, firstPayment: false },
  activeInstitute: { key: "institute", active: true, firstPayment: false },
  activeFreshInstitute: { key: "institute", active: true, firstPayment: true },
  disableInstitute: { key: "institute", active: false, firstPayment: false },
  activeAcademic: { key: "academic", active: true, firstPayment: false },
  activeFreshAcademic: { key: "academic", active: true, firstPayment: true },
  disableAcademic: { key: "academic", active: false, firstPayment: false },
}

export const enrollPrograms = {
  academic: { key: 1, text: 'Academic Program' },
  abc: { key: 2, text: 'Academic Bridging Course' },
  institutes: { key: 3, text: 'Institutes & Center' },
  shortCourse: { key: 4, text: 'Short Course' },
}

export const RegistrarStatus = {
  pending: { key: 1, text: 'Pending' },
  done: { key: 2, text: 'Done' },
  complete: { key: 3, text: 'Complete' },
  disables: { key: 4, text: 'Disabled' },
}

export const InvoiceTypesObj = {
  testing: { key: 1, text: 'Testing' },
  schoolFee: { key: 2, text: 'School Fee' },
  shortCourse: { key: 3, text: 'Short Course' },
}

export const enrollStatus = [
  { key: 1, text: "Pending" },
  { key: 2, text: "Add" },
  { key: 3, text: "Drop" }
];

export const allocatedStatus = [
  { key: 0, text: "Opening" },
  { key: 1, text: "Pending" },
  { key: 2, text: "Hold" },
  { key: 3, text: "Complete" }
];

export const allocatedStatusObj = {
  opening: { key: 0, text: "Opening" },
  pending: { key: 1, text: "Pending" },
  hold: { key: 2, text: "Hold" },
  complete: { key: 3, text: "Complete" }
};

export const enrollmentTypes = {
  PSIS: { key: "PSIS", text: "PSIS Program" },
  institute: { key: "INSTITUTE", text: "Institute program" },
  academic: { key: "ACADEMIC", text: "Academic Program" }
};

export const status = [
  { key: 1, text: "Active" },
  { key: 2, text: "Pending" },
  { key: 3, text: "Disabled" },
  { key: 4, text: "Closed" }
];

export const recordStatus = {
  active: { key: 1, text: 'Active' },
  disables: { key: 2, text: 'Disabled' },
}
export const REQUEST_STATUS = {
  pending: { key: 1, text: 'Pending' },
  approved: { key: 2, text: 'Approved' },
  canceled: { key: 3, text: 'Rejected' },
}

export const FREQUEST_STATUS = {
  pending: { key: 1, text: 'Pending' },
  approved: { key: 2, text: 'Approved' },
  rejected: { key: 3, text: 'Rejected' },
}
export const admissionPrograms = {
  academic: { key: 1, text: 'Academic Program' },
  abc: { key: 2, text: 'ABC Course' },
  institutes: { key: 3, text: 'Institutes & Center' },
  shortCourse: { key: 4, text: 'Short Course' },
}

export const TestFeeStatus = {
  paid: { key: 1, name: 'Paid' },
  unpaid: { key: 2, name: 'Unpaid' },
  prepaid: { key: 3, name: 'Prepaid' },
  void: { key: 4, name: 'Void' },
  disables: { key: 5, name: 'Disables' },
}
export const searchQRFilterBy = [
  { key: "name", text: "Class" },
  { key: "serial_id", text: "Serial No" },
  { key: "puc_id", text: "Student ID" },
  { key: "full_name", text: "Student Name" },
];

export const StudentCardFilterBy = [
  { key: "puc_id", text: "Student ID" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }
];

export const InstructorCardFilterBy = [
  { key: "code", text: "ID" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }

];

export const searchFilterBy = [
  { key: "puc_id", text: "Student ID" },
  { key: "serial_id", text: "Serial No" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }
];

export const scheduleProgram = {
  academic: { key: "academic", text: "Academic Program" },
  abc: { key: "abc", text: "Academic Bridging Course" },
  shortCourse: { key: "short_course", text: "Short Course" },
  psis: { key: "psis", text: "PSIS Program" },
}

export const Days = [
  { key: 1, name: 'Monday' },
  { key: 2, name: 'Tuesday' },
  { key: 3, name: 'Wednesday' },
  { key: 4, name: 'Thursday' },
  { key: 5, name: 'Friday' },
  { key: 6, name: 'Saturday' },
  { key: 7, name: 'Sunday' },
]

export const locationTypes = [
  { key: 1, text: "Phnom Penh" },
  { key: 2, text: "Province" }
];

export const roomTypes = [
  { key: 1, text: "Class Room" },
  { key: 2, text: "Computer Lab" },
  { key: 3, text: "Conference Room" },
  { key: 4, text: "General" },
  { key: 5, text: "Meeting Room" },
  { key: 6, text: "LCD" },
  { key: 7, text: "Big" },
  { key: 8, text: "Medium" },
  { key: 9, text: "Small" },
  { key: 10, text: "Science Lab" },
];

export const floors = [
  { key: 0, text: "Ground Floor" },
  { key: 1, text: "1st Floor" },
  { key: 2, text: "2nd Floor" },
  { key: 3, text: "3rd Floor" },
  { key: 4, text: "4th Floor" },
  { key: 5, text: "5th Floor" },
  { key: 6, text: "6th Floor" },
  { key: 7, text: "7th Floor" },
  { key: 8, text: "8th Floor" },
  { key: 9, text: "9th Floor" },
  { key: 10, text: "10th Floor" },
  { key: 11, text: "11th Floor" },
  { key: 12, text: "12th Floor" },
  { key: 13, text: "13th Floor" },
  { key: 14, text: "14th Floor" },
  { key: 15, text: "15th Floor" },
];

export const studyTypes = [
  { key: 1, text: "Week Day" },
  { key: 2, text: "Weekend" }
];

export const STATUS_ARRAY_DATA = [
  { key: 1, text: "Active" },
  { key: 3, text: "Disabled" },
];
export const statusArray = [
  { key: 1, text: "Active" },
  { key: 2, text: "Pending" },
  { key: 3, text: "Disabled" },
  { key: 4, text: "Closed" }
];
export const SCHOLARSHIP_TYPE = [
  { key: 1, text: "Academic" },
  { key: 2, text: "Other" },
];
export const SCHOLARSHIP_TYPE_OBJ = {
  academic: { key: 1, text: "Academic" },
  other: { key: 2, text: "Other" },
};

export const YEAR_LIST = [
  { key: 2020, text: "2020-2021" },
  { key: 2021, text: "2021-2022" },
  { key: 2022, text: "2022-2023" },
  { key: 2023, text: "2023-2024" },
  { key: 2024, text: "2024-2025" },
  { key: 2025, text: "2025-2026" },
  { key: 2026, text: "2026-2027" },
  { key: 2027, text: "2027-2028" },
  { key: 2028, text: "2028-2029" },
  { key: 2029, text: "2029-2030" },
  { key: 2030, text: "2030-2031" },
];
export const scholarStatus = [
  { key: 1, text: "Active" },
  { key: 2, text: "Finished" },
  { key: 3, text: "Terminate" },
  { key: 4, text: "Disabled" },
];

export const pucsearchFilterBy = [
  { key: "puc_id", text: "ID" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }
];
export const paymentStatus = {
  paid: { key: 1, text: "Paid" },
  unpaid: { key: 2, text: "Unpaid" },
  prepaid: { key: 3, text: "Prepaid" },
  void: { key: 4, text: "Void" },
};

export const EnrollStatus = {
  none: { key: 1, text: "None" },
  add: { key: 2, text: "Add" },
  change: { key: 3, text: "Changed" },
  drop: { key: 4, text: "Dropped" },
  assign: { key: 5, text: "Assign" }
};

export const scholarshipTypes = {
  monk: { key: 0, text: 'Monk' },
  scholarship: { key: 1, text: 'Scholarship' },
  loan: { key: 2, text: 'Loan' },
  sibling: { key: 3, text: 'Sibling' },
};


export const reportFilterBy = {
  today: { key: 'today', text: 'Today' },
  this_week: { key: 'this_week', text: 'This week' },
  this_month: { key: 'this_month', text: 'This month' },
  this_term: { key: 'this_term', text: 'This term' },
  this_year: { key: 'this_year', text: 'This year' },
  custom: { key: 'custom', text: 'Custom' },
};
export const reportFilterByCampus = [
  { path: '/campus/', slabel: '/today', label: 'Today', icon: "playlist_add_check" },
  { path: '/campus/', slabel: '/this_week', label: 'This week', icon: "location_on" },
  { path: '/campus/', slabel: '/this_month', label: 'This month', icon: "local_offer" },
  { path: '/campus/', slabel: '/this_term', label: 'This term', icon: "phone_iphone" },
  { path: '/campus/', slabel: '/this_year', label: 'This year', icon: "phone_iphone" },
  { path: '/campus/', slabel: '/custom', label: 'Custom', icon: "phone_iphone" },
];

export const miscellaneousPayment = {
  today: { key: 'today', text: 'Today' },
  this_week: { key: 'this_week', text: 'This week' },
  this_month: { key: 'this_month', text: 'This month' },
  this_term: { key: 'this_term', text: 'This term' },
  this_year: { key: 'this_year', text: 'This year' },
  custom: { key: 'custom', text: 'Custom' },
};


export const userRoles = [
  { key: 1, text: "Administrator" },
  { key: 2, text: "Testing" },
  // { key: 3, text: "Cashier" },
  { key: 4, text: "DAA" },
  { key: 5, text: "Enrollment" },
  { key: 6, text: "Registrar" },
  { key: 7, text: "Faculty Manager" },
  { key: 8, text: "Scholarship" },
  { key: 9, text: "Human Resource" },
  { key: 10, text: "Financial" },
  { key: 11, text: "Payroll" },
  { key: 12, text: "English Program" },
  { key: 13, text: "Reporting Server" },
];

export const userRolesObj = {
  administrator: { key: 1, text: "Administrator" },
  testing: { key: 2, text: "Testing" },
  cashier: { key: 3, text: "Cashier" },
  DAA: { key: 4, text: "DAA" },
  enrollment: { key: 5, text: "Enrollment" },
  registrar: { key: 6, text: "Registrar" },
  faculty_manager: { key: 7, text: "Faculty Manager" },
  scholarship: { key: 8, text: "Scholarship" },
  human_resource: { key: 9, text: "Human Resource" },
  financial: { key: 10, text: "Financial" },
  payroll: { key: 11, text: "Payroll" },
  englishProgram: { key: 12, text: "English Program" },
  reportingServer: { key: 13, text: "Reporting Server" },
};


export const studentTypes = {
  pucStudent: { key: 1, text: "Student" },
  assign: { key: 2, text: "Assign to Academic Student" },
  transferStudent: { key: 3, text: "Transfer Student" }
};

export const admissionType = {
  psis: { key: 1, text: 'PSIS', },
  institute: { key: 2, text: 'Institute' },
  academic: { key: 3, text: 'Academic' },
}

export const academicProgramObj = {
  associate: { key: 'associate', routerId: 'associate', active: true, icon: 'supervised_user_circle', name: 'Associate', order: 0, text: 'Associate', level: 'Undergraduate', type: 'ACADEMIC' },
  bachelor: { key: 'bachelor', routerId: 'bachelor', active: true, icon: 'supervised_user_circle', name: 'Bachelor', order: 1, text: 'Bachelor', level: 'Undergraduate', type: 'ACADEMIC' },
  pre_master: { key: 'pre_master', routerId: 'pre_master', active: true, icon: 'supervised_user_circle', name: 'Pre Master', order: 2, text: 'Pre Master', level: 'Pre Master', type: 'ACADEMIC' },
  master: { key: 'master', routerId: 'master', active: true, icon: 'supervised_user_circle', name: 'Master', order: 3, text: 'Master', level: 'Graduate', type: 'ACADEMIC' },
  postgraduate: { key: 'postgraduate', routerId: 'postgraduate', active: true, icon: 'supervised_user_circle', name: 'Doctor or PhD', order: 4, text: 'Doctor or PhD', level: 'Graduate', type: 'ACADEMIC' },
}

export const DISCOUNT_TYPES = [
  { key: 1, text: 'Percentage' },
  { key: 2, text: 'Cash' },
]

export const PROGRAM_OPTION = [
  { key: 1, text: 'Year Base' },
  { key: 2, text: 'Term Base' },
]

export const StatusObj = {
  DISABLED: { key: 0, text: "Disabled" },
  ACTIVE: { key: 1, text: "Active" },
};

export const instructorFilterBy = [
  { key: "full_name", text: "Name" },
  { key: "code", text: "Code" },
];

export const scheduleTypes = {
  accelerate: { key: 0, text: "Accelerate Course" },
  abc: { key: 1, text: "ABC Course" },
  freshman: { key: 2, text: "Freshman Batch Schedules" },
  academic: { key: 3, text: "Academic Schedules" },
  shortCourse: { key: 4, text: "Short Course Schedules" },
  other: { key: 5, text: "Other Course Schedules" }
};


export const PARENTS_STATUS = [
  { key: 1, text: 'Living Together' },
  { key: 2, text: 'Living Apart' },
  { key: 3, text: 'Divorce' },
  { key: 4, text: 'Widow / Widower' },
];


export const STUDENT_STAY_WITH = [
  { key: 1, text: 'Parents' },
  { key: 2, text: 'Mother' },
  { key: 3, text: 'Father' },
  { key: 4, text: 'Others' },
];
export const GEP_TESTING_TYPES = {
  MCQ: { key: 1, name: 'MCQ' },
  READING: { key: 2, name: 'Reading' },
  WRITING: { key: 3, name: 'Writing' },
}

export const EXAM_STATUS = [
  { key: 1, text: "Inactive" },
  { key: 2, text: "Active" },
  { key: 3, text: "Start" },
];
export const EXAM_STATUS_OBJ = {
  inactive:{ key: 1, text: "Inactive" },
  active:{ key: 2, text: "Active" },
  start:{ key: 3, text: "Start" },
};
export const Gender = [
  { key: 1, text: 'Male' },
  { key: 2, text: 'Female' },
  { key: 3, text: 'Monk' },
];

export const GenderObj = {
  male: { key: 1, text: 'Male' },
  female: { key: 2, text: 'Female' },
  monk: { key: 3, text: 'Monk' },
};


export const CAN_STATUS = [
  { key: 1, text: 'Yes' },
  { key: 2, text: 'No' },
];

export const SUBJECT_DATA = {
  khmer: { key: 1, name: 'ភាសាខ្មែរ', enName: 'Khmer' },
  math : { key: 2, name: 'គណិតវិទ្យា', enName: 'Math' },
  english : { key:3, name: "ភាសាអង់គ្លេស", enName: "English"}
}

export const khChar = [
  {key:1, text:'ក'},
  {key:2, text:'ខ'},
  {key:3, text:'គ'},
  {key:4, text:'ឃ'},
  {key:5, text:'ង'},
  {key:6, text:'ច'},
  {key:7, text:'ឆ'},
  {key:8, text:'ជ'},
  {key:9, text:'ឈ'},
  {key:10, text:'ញ'},
  {key:11, text:'ដ'},
  {key:12, text:'ឋ'},
  {key:13, text:'ឌ'},
  {key:14, text:'ឍ'},
  {key:15, text:'ណ'},
  {key:16, text:'ត'},
  {key:17, text:'ថ'},
  {key:18, text:'ទ'},
  {key:19, text:'ធ'},
  {key:20, text:'ន'},
  {key:21, text:'ប'},
  {key:22, text:'ផ'},
  {key:23, text:'ព'},
  {key:24, text:'ភ'},
  {key:25, text:'ម'},
  {key:26, text:'យ'},
  {key:27, text:'រ'},
  {key:28, text:'ល'},
  {key:29, text:'វ'},
  {key:30, text:'ស'},
  {key:31, text:'ហ'},
  {key:32, text:'ឡ'},
  {key:33, text:'អ'},
]
export const romanChar = [
  {key:1, text:'I'},
  {key:2, text:'II'},
  {key:3, text:'III'},
  {key:4, text:'IV'},
  {key:5, text:'V'},
  {key:6, text:'VI'},
  {key:7, text:'VII'},
  {key:8, text:'VIII'},
  {key:9, text:'IX'},
  {key:10, text:'X'},
  {key:11, text:'XI'},
  {key:12, text:'XII'},
  {key:13, text:'XIII'},
  {key:14, text:'XIV'},
  {key:15, text:'XV'},
  {key:16, text:'XVI'},
  {key:17, text:'XVII'},
  {key:18, text:'XVIII'},
  {key:19, text:'XIX'},
  {key:20, text:'XX'},
  {key:21, text:'XXI'},
  {key:22, text:'XXII'},
  {key:23, text:'XXIII'},
  {key:24, text:'XXIV'},
  {key:25, text:'XXV'},
  {key:26, text:'XXVI'},
  {key:27, text:'XXVII'},
  {key:28, text:'XXVIII'},
  {key:29, text:'XXIX'},
  {key:30, text:'XXX'},
]

export const ACCOUNT_TRANSACTION_TYPES_OBJ = {
  'DEBIT': { key: 1, text: 'Debit' },
  'CREDIT': { key: 2, text: 'Credit' },
}

export const ACCOUNT_TRANSACTION_TYPES_LIST = [
  { key: 1, text: 'Debit' },
  { key: 2, text: 'Credit' },
]

export const ACCOUNT_LINE_TYPE_OBJ={
  'JOURNAL_ENTRY': { key: 1, text: 'Journal Entry' },
}
