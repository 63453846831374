import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { swhichLabel, swhichRemarkLabel } from '../services/convert.service';
import { getTimePolicy, groupBy, MappingService, pushToArray, pushToObject, toDateKey } from '../services/mapping.service';

@Pipe({
  name: 'sumReport'
})
export class SumReportPipe implements PipeTransform {

  transform(value: any, data?: Array<any>, key?: any, sumField?: any): any {
    if (!data || data.length == 0) return 0;
    const filter = data.filter(m => m.invoice_type.key === key);
    const total = MappingService.sum(filter.slice(), sumField);
    return total;
  }
}

@Pipe({
  name: 'attendenceSubject'
})
export class attendenceSubjectPipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(batchKey: string, scheduleKey: string, field: string) {
    const schedules = pushToObject(await this.afs.collection('institute_training_level_batch').doc(batchKey).collection('schedules').doc(scheduleKey).get().toPromise());
    return schedules.schedule_subject.subject.name;
  }
}

@Pipe({
  name: 'sumAttendence'
})
export class SumAttendencePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(schoolKey: string, batchKey: string, date: any) {
    const dateKey = toDateKey(date);
    const att = pushToObject(await this.afs.collection('stores').doc(schoolKey).collection('sys_option').doc('general').get().toPromise());
    const data = pushToArray(await this.afs.collection('stores').doc(schoolKey).collection('student_attendance_movement', ref => ref.where('batchKey', '==', batchKey).where('checkIn_date_key', '==', dateKey).where('remark.key', '==', att['attendanceA'].key)).get().toPromise());
    const gdata = groupBy(data, 'studentKey', 'studentKey')
    return 'Absent:' + ' ' + gdata?.length + ' ' + 'students';
  }
}

@Pipe({
  name: 'studentAttendence'
})
export class StudentAttendencePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(studentKey: string, date: any, shift: string) {
    const dateKey = toDateKey(date);
    const fromDate = moment(date).startOf("day").toDate();
    const toDate = moment(date).endOf("day").toDate();
    const scanTime = 7 //toHourMM(createDate);
    const currentHours = getTimePolicy(scanTime);
    const data = pushToArray(await this.afs.collection('students').doc(studentKey).collection('time_attendance', ref => ref.where("create_date", ">=", fromDate)
      .where("create_date", "<=", toDate)).get().toPromise());
    let gdata = null
    if (!data) return null
    // gdata = data.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour === currentHour.fromHour && m.policy_hour.toHour === currentHour.toHour
    //   || m.remark.name === "P" || m.remark.name === "M")[0]
    gdata = data.filter(m => m.policy_type.key === 1 || m.remark.name === "P" || m.remark.name === "M")[0]
    return swhichLabel(gdata ? gdata : null)
  }
}

@Pipe({
  name: 'studentAttendanceD'
})
export class studentAttendanceDPipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  transform(dateKey: any, attendanceLists: any) {
    let gdata = null
    gdata = attendanceLists.find(m => m.create_date_key === dateKey)
    return swhichLabel(gdata ? gdata : null)
  }
}

@Pipe({
  name: 'studentAttendanceDate'
})
export class studentAttendanceDatePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  transform(dateKey: any, attendanceLists: any) {
    let gdata = null
    const arrayDateKey = toDateKey(dateKey)
    gdata = attendanceLists.find(m => m.create_date_key === arrayDateKey)
    return swhichLabel(gdata ? gdata : null)
  }
}

@Pipe({
  name: 'scanTime'
})
export class ScanTimePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(studentKey: string, date: any, type: number) {
    const dateKey = toDateKey(date);
    const fromDate = moment(date).startOf("day").toDate();
    const toDate = moment(date).endOf("day").toDate();
    const scanTime = 7 //toHourMM(createDate);
    const currentHour = getTimePolicy(scanTime);
    const data = pushToArray(await this.afs.collection('students').doc(studentKey).collection('time_attendance', ref => ref.where("create_date", ">=", fromDate)
      .where("create_date", "<=", toDate)).get().toPromise());
    let gdata = null
    if (type === 1) {
      // gdata = data.filter(m => m.create_type.key === 1 && m.policy_type.key === 1 && m.policy_hour.fromHour === currentHour.fromHour && m.policy_hour.toHour === currentHour.toHour
      //   || m.remark.name === "P" || m.remark.name === "M")[0]

      gdata = data.filter(m => m.create_type.key === 1 && m.policy_type.key === 1
        || m.remark.name === "P" || m.remark.name === "M")[0]
    }
    if (type === 2) {
      gdata = data.filter(f => f.create_type.key === 1 && f.policy_type.key === 2)[0]
    }
    return gdata ? gdata?.scan_date.toDate() : null
  }
}

@Pipe({
  name: 'attNote'
})
export class attNotePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(studentKey: string, date: any) {
    const dateKey = toDateKey(date);
    const fromDate = moment(date).startOf("day").toDate();
    const toDate = moment(date).endOf("day").toDate();
    const scanTime = 7 //toHourMM(createDate);
    const currentHour = getTimePolicy(scanTime);
    const data = pushToArray(await this.afs.collection('students').doc(studentKey).collection('time_attendance', ref => ref.where("create_date", ">=", fromDate)
      .where("create_date", "<=", toDate)).get().toPromise());
    let gdata = null
    if (!data) return null
    gdata = data.filter(m => m.policy_type.key === 1
      || m.remark.name === "P" || m.remark.name === "M")[0]

    // gdata = data.filter(m => m.policy_type.key === 1 && m.policy_hour.fromHour === currentHour.fromHour && m.policy_hour.toHour === currentHour.toHour
    //   || m.remark.name === "P" || m.remark.name === "M")[0]
    return gdata ? gdata.note : null
  }
}

@Pipe({
  name: 'remarkAttendence'
})
export class RemarkAttendencePipe implements PipeTransform {
  constructor(
  ) { }
  async transform(remark: any) {
    return swhichRemarkLabel(remark)
  }
}

@Pipe({
  name: 'countAttendence'
})
export class CountAttendencePipe implements PipeTransform {
  constructor(
  ) { }
  async transform(attendance: any, late: boolean, absent: boolean) {
    let att;
    if (late) {
      att = attendance.filter(m => m.remark.name === 'L')
    }
    if (absent) {
      att = attendance.filter(m => m.remark.name === 'A')
    }
    return att.length
  }
}
