import { DataService } from "./../services/data.service";
import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { AngularFirestoreCollection } from "@angular/fire/firestore";
import { academicYearObj, MappingService, pushToArray } from 'src/app/shared/services/mapping.service';
import { PROGRAM_OPTION_OBJ, ACADEMIC_YEAR_STATUS } from '../dummy/stauts';

@Injectable({ providedIn: 'root' })
export class BaseStore {
  @observable public data = [];
  @observable public subjectScore = [];
  @observable public selectedGrade = null;
  @observable public sessionShift = [];
  @observable public campusGroup = [];
  @observable public loading = false;
  @observable public config = null;
  @observable public process = false;
  @observable public empty = false;

  @observable public templateItems: any[] = [];
  @observable public templateGroups: any[] = [];
  @observable public totalTemplateItems: 0;

  @observable public options: any[] = [];
  @observable public STRING_TOPIC: any[] = [];
  @observable public TOPIC_STRINGS: any[] = [];
  @observable public TOPIC_FILE: any[] = [];

  @observable public STRING_TOPIC_ANSWERS: any[] = [];
  @observable public WORD_BANK: any[] = [];


  subscribeBaseData: any = null;
  constructor(private ds: DataService) { }

  @action
  async fetchAcademicYear(schoolKey) {
    this.process = true;
    const docs = await this.ds.academicYearRef(schoolKey).get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  async fetchAcademicGrade() {
    this.process = true;
    const docs = await this.ds.academicGradeRef().get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  async fetchCampus(schoolKey) {
    this.process = true;
    const docs = await this.ds.storeDocRef(schoolKey).collection("campus", ref => ref.orderBy("order")).get().toPromise();
    this.process = false;
    return pushToArray(docs)
  }

  @action
  async fetchShift() {
    const docs = await this.ds.shiftDBRef().get().toPromise();
    return MappingService.orderBy(pushToArray(docs), "order");
  }

  @action
  async fetchTrainingProgram(campusKey: string) {
    const docs = await this.ds.campusRef().doc(campusKey).collection("training_programs").get().toPromise();
    let data = pushToArray(docs);
    data = MappingService.orderBy(data, "name");
    return data;
  }

  @action
  async fetchTrainingLevel(campusKey: string, programKey: string) {
    const docs = await this.ds.campusRef().doc(campusKey).collection("training_levels", ref => ref.where("program.key", "==", programKey)).get().toPromise();
    let data = pushToArray(docs);
    data = MappingService.orderBy(data, "grade.order");
    return data;
  }

  fetchTrainingSchoolFee(campusKey: string, levelKey: string) {
    this.process = true;
    this.templateItems = [];
    this.templateGroups = [];
    this.ds.trainingSchoolFeeRef(campusKey, levelKey).valueChanges().subscribe(docs => {
      this.templateItems = docs;
      this.templateGroups = MappingService.groupByDesc(docs, "paymentOption.key", "paymentOption.order")
      this.process = false;
    })
  }

  async fetchList(ref: any) {
    this.process = true;
    const docs = await ref.get().toPromise();
    const data = MappingService.pushToArray(docs)
    this.process = false;
    return data;
  }

  async fetchDoc(ref: any) {
    this.process = true;
    const doc = await ref.get().toPromise();
    const data = MappingService.pushToObject(doc)
    this.process = false;
    return data;
  }

  @action
  fetchGrade(callback) {
    this.loading = true;
    this.ds.resourceEducationGradeRef().valueChanges().subscribe(docs => {
      this.data = MappingService.orderBy(docs, "order");
      this.empty = docs.length === 0;
      this.loading = false;
      callback(docs)
    })
  }

  @action
  fetchGradeDoc(key: string) {
    this.loading = true;
    this.ds.resourceEducationGradeRef().doc(key).valueChanges().subscribe(doc => {
      this.selectedGrade = doc;
      this.loading = false;
    })
  }

  @action
  fetchSubjectScore(key: string) {
    this.loading = true;
    this.ds.resourceEducationSubjectScoreByGradeRef(key).valueChanges().subscribe(docs => {
      this.empty = docs.length === 0;
      this.subjectScore = MappingService.orderByDesc(docs, "multiple");
      this.loading = false;
    })
  }

  @action
  fetchData(ref: any) {
    this.loading = true;
    this.subscribeBaseData = ref.valueChanges().subscribe((docs) => {
      this.options = [];
      this.WORD_BANK = [];
      this.data = docs;
      this.empty = docs.length === 0;
      this.loading = false;
    })
  }

  @action
  fetchBuilding(ref: any) {
    this.loading = true;
    ref.valueChanges().subscribe(docs => {
      this.data = docs;
      this.campusGroup = MappingService.groupBy(docs, "campus.key", "campus.order");
      this.empty = docs.length === 0;
      this.loading = false;
    });
  }

  @observable fetchClassroomRef: any = null;
  @action
  fetchClassroom(ref: any) {
    this.loading = true;
    this.fetchClassroomRef = ref.valueChanges().subscribe(docs => {
      this.data = docs;
      this.empty = docs.length === 0;
      this.loading = false;
    });
  }

  @action
  fetchSession(ref: any) {
    this.loading = true;
    ref.valueChanges().subscribe(docs => {
      this.data = docs;
      this.sessionShift = MappingService.groupBy(docs, "shift.key", "shift.order");
      this.empty = docs.length === 0;
      this.loading = false;
    });
  }

  @action
  async activeTerm(item: any, user: any, callback) {
    const batch = this.ds.batch();
    this.loading = true;
    const { key, termType, schoolKey } = item

    const envDoc = await this.ds.storeDocRef(schoolKey).collection("academic_environment").doc("academic_environment").get().toPromise();
    const envData = MappingService.pushToObject(envDoc)
    const { year, term } = envData
    const oldAcademicYear = termType.key === PROGRAM_OPTION_OBJ.yearBase.key ? year : term

    const schoolRef = this.ds.firestore().collection("stores").doc(schoolKey)
    const yearRef = this.ds.academicYearFireRef()

    let data = null;
    if (termType.key === PROGRAM_OPTION_OBJ.yearBase.key) {
      data = {
        year: academicYearObj(item)
      }
    } else {
      data = {
        term: academicYearObj(item)
      }
    }

    //CLOSE ACADEMIC YEAR
    const closeData = {
      yearStatus: ACADEMIC_YEAR_STATUS[2],
      closed_at: new Date(),
      closed_by: user.key,
    }
    // batch.update(yearRef.doc(oldAcademicYear.key), { ...closeData })
    // batch.update(schoolRef.collection("academic_year").doc(oldAcademicYear.key), { ...closeData })

    //NEW ACADEMIC YEAR
    batch.update(schoolRef.collection("academic_environment").doc("academic_environment"), { ...data })

    const activeData = {
      yearStatus: ACADEMIC_YEAR_STATUS[1],
      active_at: new Date(),
      active_by: user.key,
    }

    batch.update(yearRef.doc(key), { ...activeData })
    batch.update(schoolRef.collection("academic_year").doc(key), { ...activeData })

    batch.commit().then(() => {
      this.loading = false;
      callback(true, null);
    }).catch(error => {
      this.loading = false;
      callback(false, error);
    })
  }

  @action
  async cloneLevelFee(oldLevelKey: string, campusKey: string, newLevelKey: string, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const campusRef = this.ds.campusFireRef().doc(campusKey).collection("training_levels").doc(newLevelKey);

    const feeDocs = await this.ds.campusRef().doc(campusKey).collection("training_levels").doc(oldLevelKey).collection("training_school_fee").get().toPromise();
    const feeData = MappingService.pushToArray(feeDocs);

    if (feeData && feeData.length > 0) {
      feeData.forEach(m => {
        const data = {
          ...m,
          key: this.ds.createId(),
        }
        batch.set(campusRef.collection("training_school_fee").doc(data.key), data);
      })
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }


  @action
  addNewQuestion(ref: AngularFirestoreCollection, question, item: any, callback) {
    this.process = true;
    ref.doc(question.key).collection("questions").doc(item.key).set(item).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  addNewSub(collection: string, item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const ref = this.ds.collectionFireRef(collection).doc(item.key)
    const schoolRef = this.ds.collectionFireRef("stores").doc(item.schoolKey).collection(collection).doc(item.key)

    batch.set(ref, item)
    batch.set(schoolRef, item)
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  addNew(ref: any, item: any, callback) {
    this.process = true;
    ref.doc(item.key).set(item).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  edit(ref: any, item: any, callback) {
    this.process = true;
    ref.doc(item.key).update(item).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  update(ref: any, item: any, callback) {
    this.process = true;
    ref.doc(item.key).update(item).then(() => {
      this.process = false;
      callback(true, item)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    });
  }

  @action
  updateSub(collection: string, item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const ref = this.ds.collectionFireRef(collection).doc(item.key)
    const schoolRef = this.ds.collectionFireRef("stores").doc(item.schoolKey).collection(collection).doc(item.key)

    batch.update(ref, item)
    batch.update(schoolRef, item)
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  deleteSub(collection: string, item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const ref = this.ds.collectionFireRef(collection).doc(item.key)
    const schoolRef = this.ds.collectionFireRef("stores").doc(item.schoolKey).collection(collection).doc(item.key)

    batch.delete(ref)
    batch.delete(schoolRef)
    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  delete(ref: any, item: any, callback) {
    this.process = true;
    ref.doc(item.key).delete().then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

}