// export const SCHOOL = {
//   key:"sovannaphumi_school",
//   name: "Sovannaphumi School",
//   name_kh: "សាលារៀនសុវណ្ណភូមិ",
//   email: "Info@sovannaphumin.edu.kh",
//   website: "www.sovannaphumin.edu.kh",
// img:'/assets/images/sps.png',

// };
// export const SCHOOL = {
//   key:"apis_school",
//   name: "American Pacific International School",
//   name_kh: "សាលាអន្តរជាតិអាមេរិកាំងប៉ាស៊ីហ្វិក",
//   email: "info@apis.edu.kh",
//   website: "www.apis.edu.kh",
//   short_name: "APIS",
//   img:'../../../assets/images/apis.png',
//   address:'#145, St.160, Sangkat Toek Laak II, Khan Toul Kork, Phnom Penh, Cambodia',
//   phone:'(+855) 15 838 182',
// };
export const SCHOOL = {
  key: 'ewis_school',
  name: "East-West International School",
  name_kh: "សាលាអន្តរជាតិ អុិសវ៉េស",
  short_name: "EWIS",
  email: "Info@ewiscambodia.edu.kh",
  website: "www.ewiscambodia.edu.kh",
  img: '/assets/images/ewis.png',
  // address: '#145, St.160, Sangkat Toek Laak II, Khan Toul Kork, Phnom Penh, Cambodia',
  // phone: '(+855) 15 838 182',
};


export const LEVEL_OF_MANAGEMENT = [
  { key: 1, text: "Management" },
  { key: 2, text: "Schools" },
  { key: 3, text: "University" },
];

export const LEVEL_OF_MANAGEMENT_OBJECT = {
  management: { key: 1, text: "Management" },
  schools: { key: 2, text: "schools" },
  university: { key: 3, text: "University" },
};
export const PRODUCT_TYPE_OPTIONS = [
  { key: 1, router: 'inventory', name: 'Inventory' },
  { key: 0, router: 'non-inventory', name: 'Non-Inventory' },
  { key: 3, router: 'bundle', name: 'bundle' },
]

export const FILTER_OPTIONS = [
  { key: "code", text: "ID" },
  { key: "full_name", text: "Full name" },
  { key: "first_name", text: "First name" },
  { key: "last_name", text: "Last name" },
  { key: "mobile_phone", text: "Phone number" }
];
export const FILTER_OPTIONS_STUDENT = [
  { key: "code", text: "ID" },
  { key: "full_name", text: "Full name" },
  // { key: "first_name", text: "First name" },
  // { key: "last_name", text: "Last name" },
  // { key: "mobile_phone", text: "Phone number" }
];
export const APP_NAME = {
  'human-resource': { key: 'human-resource', router: 'human-resource', name: 'Human Resource' }
}

export const APP_NAME_LIST = [
  { key: 'human-resource', router: 'human-resource', name: 'Human Resource' },
  { key: 'inventory', router: 'inventory', name: 'Inventory Management' },
  { key: 'testing-center', router: 'testing-center', name: 'Testing Center' },
  { key: 'finance-report', router: 'finance-report', name: 'Account & Finance' },
  { key: 'scholarships', router: 'scholarships', name: 'Scholarships Office' },
  { key: 'registrar', router: 'registrar', name: 'Registrar Office' },
  { key: 'administrator', router: 'administrator', name: 'Administrator' },
  { key: 'analytics', router: 'analytics', name: 'Analytics' },
  { key: 'class-manager', router: 'class-manager', name: 'Class Manager' },

]

export const PRODUCT_TYPE_OPTION_OBJ = {
  'inventory': { key: 1, router: 'inventory', name: 'Inventory' },
  'non-inventory': { key: 0, router: 'non-inventory', name: 'Non-Inventory' },
  'bundle': { key: 3, router: 'bundle', name: 'Bundle' },
  'services': { key: 2, router: 'services', name: 'Services' },
}

export const APP_FUNCTIONS = {
  'registrar': {
    key: 'registrar',
    routeItems: [
      {
        key: 'grade-sheets',
        name: 'Grade Sheets',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'Dashboard',
        data: []
      },
      {
        key: 'reports',
        name: 'Reports',
        expansions: 'two',
        expansionIndex: 2,
        icon: '',
        remark: '',
        data: []
      },
      {
        key: 'grading-system',
        name: 'Grading System',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Dashboard',
        data: []
      },
    ]
  },
  'finance-report': {
    key: 'finance-report',
    routeItems: [
      {
        key: 'accounting',
        name: 'Accounting',
        expansions: 'five',
        expansionIndex: 5,
        icon: '',
        remark: 'Petty Cash, Fixed Assets, Journal Entry, Closing Entries, Chart of Accounts.',
        data: [
          { key: 'petty-cash', name: 'Petty Cash', icon: 'monetization_on' },
          { key: 'fixed-assets', name: 'Fixed Assets', icon: 'dns' },
          { key: 'inventory-fixed-assets', name: 'Inventory Fixed Assets', icon: 'personal_video' },
          { key: 'journal-entry', name: 'Journal Entry', icon: 'work' },
          { key: 'closing-entries', name: 'Closing Entries', icon: 'alarm_on' },
          { key: 'general-journal', name: 'General Journal', icon: 'flag' },
          { key: 'chart-account', name: 'Chart of Accounts', icon: 'widgets' },
          { key: 'settings', name: 'Settings', icon: 'settings' },
        ]
      },
      {
        key: 'e-payments',
        name: 'E-Payments',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'Print, Listing and Summary Scholarship Reports. ',
        data: [
          { key: 'aba-e-payment', name: 'ABA Billing', icon: 'assignment' },
          { key: 'wing-merchant-payment', name: 'Wing merchant', icon: 'language' },
          { key: 'acleda-e-payment', name: 'ACLEDA Billing', icon: 'launch' },
        ]
      },
      {
        key: 'cashier-reports',
        name: 'Cashier Reports',
        expansions: 'two',
        expansionIndex: 2,
        icon: '',
        remark: 'ABC, Schedules, Admissions and Teaching Assignment',
        data: [
          { key: 'daily-campus-listing', name: 'Daily Campus listing', icon: 'assignment' },
          { key: 'daily-shift-listing', name: 'Daily Shift listing', icon: 'language' },
          { key: 'expense-listing', name: 'Expense listing', icon: 'launch' },
          { key: 'match-term-listing', name: 'Match Term listing', icon: 'account_balance_wallet' },
        ]
      },
      {
        key: 'cashier-reports',
        name: 'Reporting',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Listing and Summary English Program Reports. ',
        data: [
          { key: 'operating-class', name: 'Operating Class', icon: 'assignment' },
          { key: 'instructor-schedule', name: 'Instructor schedule', icon: 'language' },
          { key: 'english-testing-student', name: 'Testing Student', icon: 'launch' },
          { key: 'english-new-student', name: 'New Student', icon: 'launch' },
          { key: 'english-old-student', name: 'Old Student', icon: 'launch' },
          { key: 'english-paid-student', name: 'Student Paid', icon: 'launch' },
          { key: 'english-unpaid-student', name: 'Student Unpaid', icon: 'launch' },
        ]
      },


    ]
  },
  'scholarships': {
    key: 'scholarships',
    routeItems: [
      {
        key: 'analytics',
        name: 'Analytics',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Dashboard',
        data: [
          { key: 'dashboard', name: 'Dashboard', icon: 'dashboard' },
          { key: 'overview', name: 'Overview', icon: 'launch' },

        ]
      },
      {
        key: 'student-scholarship',
        name: 'Programs',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'List scholarships student by programs',
        data: [

        ]
      },
      {
        key: 'reports-scholarship',
        name: 'Reports',
        expansions: 'two',
        expansionIndex: 2,
        icon: '',
        remark: 'Placement Test, Exam Preparation, Examinations, English Placement Test, Khmer Placement Test',
        data: [
          { key: 'listing', name: 'Listing', icon: 'assignment' },
          // { key: 'placement-test', name: 'Placement Test', icon: 'assignment' },
          // { key: 'exam-preparation', name: 'Exam Preparation', icon: 'language' },
          // { key: 'english-placement-test', name: 'English Placement Test', icon: 'launch' },
          // { key: 'khmer-placement-test', name: 'Khmer Placement Test', icon: 'public' },
        ]
      },
    ]
  },
  'testing-center': {
    key: 'testing-center',
    routeItems: [

      {
        key: 'analytics',
        name: 'Analytics',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Dashboard',
        data: [
          { key: 'dashboard', name: 'Dashboard', icon: 'dashboard' },
        ]
      },

      {
        key: 'programs',
        name: 'Programs',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'List all student tests by programs.',
        data: []
      },
      {
        key: 'placement-test',
        name: 'Placement Test',
        expansions: 'two',
        expansionIndex: 2,
        icon: '',
        remark: 'Placement Test, Exam Preparation, Examinations, English Placement Test, Khmer Placement Test',
        data: [
          { key: 'exam-preparation', name: 'Exam Preparation', icon: 'language' },
          { key: 'examinations', name: 'Examinations', icon: 'assignment' },
          { key: 'general-english-programs', name: 'English Placement Test', icon: 'launch' },
          { key: 'khmer-placement-test', name: 'Khmer Placement Test', icon: 'public' },
        ]
      },
      {
        key: 'reports',
        name: 'Reports',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Placement Test, Exam Preparation, Examinations, English Placement Test, Khmer Placement Test',
        data: [
          { key: 'listing', name: 'Listing', icon: 'language' },
        ]
      },
    ]
  },
  'inventory': {
    key: 'inventory',
    routeItems: [
      {
        key: 'purchase',
        name: 'Purchases',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Bill, Pay Bills, Debit Note, Supplier',
        data: [
          { key: 'bills', name: 'Bills', icon: 'shopping_basket' },
          // { key: 'pay-bills', name: 'Pay Bills', icon: 'description' },
          // { key: 'debit-note', name: 'Debit Note', icon: 'payment' },
          { key: 'suppliers', name: 'Suppliers', icon: 'supervisor_account' },
        ]
      },
      {
        key: 'inventory',
        name: 'Inventory',
        expansions: 'two',
        expansionIndex: 2,
        icon: '',
        remark: 'Inventory Adjustment, Inventory Transfer, Inventory Center',
        data: [
          { key: 'inventory-center', name: 'Inventory Center', icon: 'dns' },
          { key: 'received-transfer', name: 'Receive Transfer', icon: 'local_mall' },
          { key: 'inventory-transfer', name: 'Inventory Transfer', icon: 'flight_takeoff' },
          { key: 'inventory-adjustment', name: 'Inventory Adjustments', icon: 'how_to_vote' },
        ]
      },
      {
        key: 'products',
        name: 'Products',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'Products, Category, Unit Measurements, Payment Terms',
        data: [
          { key: 'products', name: 'Products', icon: 'playlist_add' },
          { key: 'category', name: 'Category', icon: 'category' },
          { key: 'unit-measurements', name: 'Unit Measurements', icon: 'transform' },
          { key: 'payment-term', name: 'Payment Terms', icon: 'schedule' },
        ]
      },


      {
        key: 'reports',
        name: 'Reporting',
        expansions: 'four',
        expansionIndex: 4,
        icon: '',
        remark: 'Listing and Summary Inventory Report. ',
        data: [
          { key: 'bills-report-listing', name: 'Bills Report Listing', icon: 'shopping_basket' },
          { key: 'inventory-report-listing', name: 'Bills Report Listing', icon: 'dns' },
          { key: 'adjustment-report-listing', name: 'Adjustment Report Listing', icon: 'how_to_vote' },
        ]
      },
    ]
  },
  'class-manager': {
    key: 'class-manager',
    routeItems: [
      {
        key: 'analytics',
        name: 'Analytics',
        expansions: 'one',
        expansionIndex: 1,
        icon: '',
        remark: 'Dashboard, Teaching Analytics, Student Analytics and Exam Analytics ',
        data: [
          // { key: 'students', name: 'General', icon: 'shopping_basket' },
          // { key: 'instructor', name: 'By Instructor', icon: 'shopping_basket' },
          { key: 'dashboard', name: 'Dashboard', icon: 'shopping_basket' },
        ]
      },
      // {
      //   key: 'develop',
      //   name: 'Develop',
      //   expansions: 'two',
      //   expansionIndex: 2,
      //   icon: '',
      //   remark: 'Pre-enrolled, Batch Schedules, , Prospective, ABC and Short Courses',
      //   data: [
      //     { key: 'assign-batches/students', name: 'Assign Batches', icon: 'shopping_basket' },
      //   ]
      // },

    ]
  },
  'analytics': {
    key: 'analytics',
    routeItems: [
      {
        key: 'overview',
        name: 'Overview',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Books, Sections, Generes, Author, Publishers and Slides. ',
        data: [
          { key: 'dashboard', name: 'Dashboard', icon: 'home' },
          { key: 'students', name: 'General', icon: 'timeline' },
          { key: 'instructor', name: 'By Instructor', icon: 'shopping_basket' },
        ]
      },
    ]
  },
  'administrator': {
    key: 'administrator',
    routeItems: [
      {
        key: 'app-center',
        name: 'App Center',
        expansions: 'two',
        expansionIndex: 2,
        icon: '',
        remark: 'Videos, News and Video Library.',
        data: [
          { key: 'announcements', name: 'Announcements', icon: 'shopping_basket' },
          { key: 'events', name: 'Events', icon: 'supervisor_account' },
          { key: 'school-programs', name: 'School Programs', icon: 'shopping_basket' },
          { key: 'partners', name: 'Partners', icon: 'supervisor_account' },
          { key: 'management-team', name: 'Management Team', icon: 'shopping_basket' },
          { key: 'videos', name: 'News Videos', icon: 'supervisor_account' },
          { key: 'news', name: 'News', icon: 'supervisor_account' },
          { key: 'video_library', name: 'Video Library', icon: 'shopping_basket' },
        ]
      },

      {
        key: 'book-store',
        name: 'library',
        expansions: 'three',
        expansionIndex: 3,
        icon: '',
        remark: 'Books, Sections, Generes, Author, Publishers and Slides. ',
        data: [
          { key: 'books', name: 'Books', icon: 'shopping_basket' },
          { key: 'sections', name: 'Sections', icon: 'supervisor_account' },
          { key: 'genres', name: 'Genres', icon: 'shopping_basket' },
          { key: 'author', name: 'Author', icon: 'supervisor_account' },
          { key: 'publisher', name: 'Publisher', icon: 'shopping_basket' },
          { key: 'slides', name: 'Slides', icon: 'supervisor_account' },
        ]
      },



    ]
  },

  'human-resource': {
    key: 'human-resource',
    routeItems: [
      // {
      //   key: 'time-and-attendance',
      //   name: 'Time and Attendance',
      //   expansions: 'one',
      //   expansionIndex: 1,
      //   icon: '',
      //   remark: 'Department, Holiday, Position, Country/Nationality',

      //   data: [
      //     { key: 'department', name: 'Departments', icon: 'flag' },
      //     { key: 'holiday', name: 'Holiday', icon: 'flag' },
      //     { key: 'position', name: 'Positions', icon: 'flag' },
      //     { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
      //   ]
      // },
      // {
      //   key: 'grow',
      //   name: 'Grow',
      //   expansions: 'two',
      //   expansionIndex: 2,
      //   icon: '',
      //   remark: 'Department, Holiday, Position, Country/Nationality',
      //   data: [
      //     { key: 'department', name: 'Department', icon: 'flag' },
      //     { key: 'holiday', name: 'Holiday', icon: 'flag' },
      //     { key: 'position', name: 'Position', icon: 'flag' },
      //     { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
      //   ]
      // },
      // {
      //   key: 'analytics',
      //   name: 'Analytics',
      //   expansions: 'three',
      //   expansionIndex: 3,
      //   icon: '',
      //   remark: 'Department, Holiday, Position, Country/Nationality',
      //   data: [
      //     { key: 'department', name: 'Department', icon: 'flag' },
      //     { key: 'holiday', name: 'Holiday', icon: 'flag' },
      //     { key: 'position', name: 'Position', icon: 'flag' },
      //     { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
      //   ]
      // },
      {
        key: 'resources',
        name: 'Resources',
        expansions: 'four',
        expansionIndex: 4,
        icon: '',
        remark: 'Employees, Teaching Assignment, Lecturer, Faculties, Programs, Campus',
        data: [
          { key: 'employees', name: 'Employees', icon: 'supervised_user_circle' },
          { key: 'employees-data', name: 'Employees Application', icon: 'supervised_user_circle' },
          // { key: 'teaching-assignment', name: 'Teaching Assignment', icon: 'perm_contact_calendar' },
          { key: 'cashier', name: 'Cashier', icon: 'school' },
          { key: 'user', name: 'User', icon: 'account_circle' },
          // { key: 'programs', name: 'Programs', icon: 'category' },
        ]
      },
      {
        key: 'list',
        name: 'List',
        expansions: 'five',
        expansionIndex: 5,
        icon: '',
        remark: 'Campus & Building, Departments, Holiday, Position, Country/Nationality',
        data: [
          { key: 'campus_and_building', name: 'Campus & Building', icon: 'place' },
          { key: 'department', name: 'Departments', icon: 'business' },
          { key: 'holiday', name: 'Holiday', icon: 'event' },
          { key: 'position', name: 'Positions', icon: 'assignment_ind' },
          { key: 'title', name: 'Titles', icon: 'assignment_ind' },
          { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
        ]
      },
      {
        key: 'reports',
        name: 'Reports',
        expansions: 'six',
        expansionIndex: 6,
        icon: '',
        remark: 'Employee Listing',
        data: [
          { key: 'employee-listing', name: 'Employee Listing', icon: 'perm_contact_calendar' },
          // { key: 'holiday', name: 'Holiday', icon: 'flag' },
          // { key: 'position', name: 'Position', icon: 'flag' },
          // { key: 'country-nationality', name: 'Country/Nationality', icon: 'flag' },
        ]
      },

    ]
  }
}

export const CREATE_TIME_TYPE = {
  security: { key: 0, text: 'Security' },
  student: { key: 1, text: 'Student' },
  teacher: { key: 2, text: 'Teacher' },
  administrator: { key: 3, text: 'Administrator' },
}

export const HR_USER_TYPES: any = {
  students: { key: 1, name: 'Students', kh_name: 'សិស្ស' },
  lectures: { key: 2, name: 'Lectures', kh_name: 'សាស្ត្រាចារ្យ' },
}
