<header id="header">
  <nav class="top-nav">
    <div class="nav-content">
      <div class="left-nav">

        <div class="brand-name" [routerLink]="['/']">
          <img [src]="school.img" />
          <span>{{auth?.appName}}<span style="margin: 0 6px;">•</span>{{auth?.selectedCampus?.name}}</span>
        </div>
        <!-- <div class="campus-select">
                    <button>s
                        <i class="material-icons">cloud_queue</i>
                        <div class="name">
                            {{user?.campus?.name}}
                            <span *ngIf="term">{{term?.code}}</span>
                        </div>
                        <i class="material-icons">arrow_drop_down</i>
                    </button>
                </div> -->
      </div>
      <div class="right-nav">
        <div class="nav-item-list">

          <button mat-button class="mat-uid-profile">
            <img [src]="auth?.user?.fileUrl" *ngIf="auth?.user?.fileUrl" />
            <svg *ngIf="!auth?.user?.fileUrl" viewBox="0 0 99.6097412109375 99.6572265625" version="1.1"
              xmlns="http://www.w3.org/2000/svg" classname=" glyph-box">
              <g transform="matrix(1 0 0 1 -8.740283203125045 85.05859375)">
                <path
                  d="M 58.5449 14.5508 C 85.791 14.5508 108.35 -8.00781 108.35 -35.2539 C 108.35 -62.4512 85.7422 -85.0586 58.4961 -85.0586 C 31.2988 -85.0586 8.74023 -62.4512 8.74023 -35.2539 C 8.74023 -8.00781 31.3477 14.5508 58.5449 14.5508 Z M 58.5449 -18.6035 C 43.8477 -18.6035 32.5684 -13.3789 27.5879 -7.51953 C 21.0449 -14.8438 17.0898 -24.5605 17.0898 -35.2539 C 17.0898 -58.252 35.4492 -76.7578 58.4961 -76.7578 C 81.543 -76.7578 100 -58.252 100.049 -35.2539 C 100.098 -24.5117 96.0938 -14.7949 89.502 -7.4707 C 84.5703 -13.3301 73.2422 -18.6035 58.5449 -18.6035 Z M 58.5449 -26.9043 C 67.9199 -26.8066 75.293 -34.8145 75.293 -45.3125 C 75.293 -55.1758 67.9199 -63.3789 58.5449 -63.3789 C 49.1699 -63.3789 41.748 -55.1758 41.7969 -45.3125 C 41.8457 -34.8145 49.1699 -27.002 58.5449 -26.9043 Z">
                </path>
              </g>
            </svg>
            <span class="uid-name">{{ auth?.user?.displayName }}</span>
          </button>
          <button matTooltip="Settings" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>
              settings
            </mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="user-options">
            <button mat-menu-item (click)="changePassword()">
              <mat-icon>verified_user</mat-icon>
              <span>Change Password</span>
            </button>
            <button mat-menu-item (click)="LinkGoogleAuthPassword()"
              *ngIf="school?.key === 'ewis_school' && !auth?.user?.isLinkedGmail">
              <mat-icon class="mat-18" style="display: inline-flex;">
                <span style="width:18px;height:18px;"
                  class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18"><svg
                    version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48"
                    class="abcRioButtonSvg">
                    <g>
                      <path fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                      </path>
                      <path fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                      </path>
                      <path fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                      </path>
                      <path fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                      </path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </g>
                  </svg>
                </span>
              </mat-icon>
              Link Google Account
            </button>

            <button mat-menu-item *ngIf="school?.key === 'ewis_school' && auth?.user?.isLinkedGmail">
              <mat-icon class="mat-18" style="display: inline-flex;">
                <span style="width:18px;height:18px;"
                  class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18"><svg
                    version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48"
                    class="abcRioButtonSvg">
                    <g>
                      <path fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                      </path>
                      <path fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                      </path>
                      <path fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                      </path>
                      <path fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                      </path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </g>
                  </svg>
                </span>
              </mat-icon>
              {{auth?.user?.email}}
            </button>
            <button mat-menu-item (click)="_signOut()">
              <mat-icon>lock</mat-icon>
              <span>Log Out</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </nav>
</header>


<div class="switch-app-wrapper">
  <div class="switch-app-header">
    <button mat-icon-button (click)="toggleSidebar()">
      <mat-icon class="mat-18">menu</mat-icon>
    </button>
  </div>
  <div class="switch-app-content">
    <div class="switch-app">
      <div class="task-content">
        <div class="task-item ani-col">
          <div class="task-icon task-icon1 green">
            <i class="material-icons">receipt</i>
          </div>
          <h2 class="task-title">Receipt Listing</h2>
        </div>
        <div class="task-item ani-col">
          <div class="task-icon task-icon1 orang">
            <i class="material-icons">assignment</i>
          </div>
          <h2 class="task-title">Payment Summary</h2>
        </div>
        <div class="task-item ani-col">
          <div class="task-icon task-icon1 purple">
            <i class="material-icons">alarm_on</i>
          </div>
          <h2 class="task-title">Shift Summary</h2>
        </div>
        <div class="task-item ani-col">
          <div class="task-icon task-icon1 sky">
            <i class="material-icons">event_note</i>
          </div>
          <h2 class="task-title">Shift Listing</h2>
        </div>

        <div class="task-item ani-col">
          <div class="task-icon">
            <i class="material-icons">group_add</i>
          </div>
          <h2 class="task-title">New Registration</h2>
        </div>
        <div class="task-item ani-col">
          <div class="task-icon">
            <i class="material-icons">local_mall</i>
          </div>
          <h2 class="task-title">Expenses</h2>
        </div>
        <div class="task-item ani-col">
          <div class="task-icon">
            <i class="material-icons">search</i>
          </div>
          <h2 class="task-title">Search</h2>
        </div>
        <div class="task-item ani-col">
          <div class="task-icon">
            <i class="material-icons">home</i>
          </div>
          <h2 class="task-title">Home</h2>
        </div>

      </div>
    </div>
  </div>
</div>
