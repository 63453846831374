<form [formGroup]="form" class="dialog-wrapper">
  <div class="dialog-header">
    <div class="title">
      Update Student Card Profile
    </div>
    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>

  <div class="dialog-content">
    <div class="form-wrapper">
      <div class="ui form">
        <div class="photo-pro">
          <div class="avatar">
            <ng-container *ngIf="data?.item?.card_fileurl && !SELECTED_PHOTO; else elseTemplate1">
              <img class="" [src]="data?.item?.card_fileurl" />
            </ng-container>
            <ng-template #elseTemplate1>
              <img [src]="URL" />
            </ng-template>
            <div (click)="photoFile.click()" class="change-profile">
              <mat-icon class="mat-icon" role="img">
                photo_camera
              </mat-icon>
            </div>
          </div>
          <input #photoFile type="file" accept='image/*' (change)="onSelectedFile($event)" style="display:none;" />
        </div>

        <div class="fields two">
          <div class="field">
            <label>Last Name (EN)<span class="rq-sign">*</span></label>
            <input type="text" #focusInput placeholder="Enter a last name" formControlName="last_name">
            <div *ngIf="form.controls['last_name'].hasError('required') && form.controls['last_name'].touched"
              class="error-message">This field is required.</div>
          </div>
          <div class="field">
            <label>First Name (EN)<span class="rq-sign">*</span></label>
            <input type="text" placeholder="Enter a first name" formControlName="first_name">
            <div *ngIf="form.controls['first_name'].hasError('required') && form.controls['first_name'].touched"
              class="error-message">This field is required.</div>
          </div>
        </div>

        <div class="fields two">

          <div class="field">
            <label>Last Name (KH)<span class="rq-sign">*</span></label>
            <input type="text" placeholder="Enter a first name" formControlName="khmer_last_name">
            <div
              *ngIf="form.controls['khmer_last_name'].hasError('required') && form.controls['khmer_last_name'].touched"
              class="error-message">This field is required.</div>
          </div>
          <div class="field">
            <label>First Name (KH)<span class="rq-sign">*</span></label>
            <input type="text" #focusInput placeholder="Enter a last name" formControlName="khmer_first_name">
            <div
              *ngIf="form.controls['khmer_first_name'].hasError('required') && form.controls['khmer_first_name'].touched"
              class="error-message">This field is required.</div>
          </div>
        </div>


        <div class="field">
          <label>Date Of Birth
             <span class="rq-sign">*</span>
          </label>
          <div class="mixfeild">
            <input type="text" date="true" (click)="dobpicker.open()" formControlName="dob"
              [matDatepicker]="dobpicker" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="dobpicker"></mat-datepicker-toggle>
            <mat-datepicker #dobpicker></mat-datepicker>
          </div>
          <div
            *ngIf="form.controls['dob'].hasError('required') && form.controls['dob'].touched"
            class="error-message">
            Date is required.
          </div>
        </div>


        <div class="fields two">
          <div class="field">
            <label>Guardian Name</label>
            <input type="text" placeholder="Enter a first name" formControlName="guardian_name">
            <!-- <div
              *ngIf="form.controls['guardian_name'].hasError('required') && form.controls['guardian_name'].touched"
              class="error-message">This field is required.</div> -->
          </div>
          <div class="field">
            <label>Guardian Phone</label>
            <input type="text" #focusInput placeholder="Enter a last name" formControlName="guardian_phone">
            <!-- <div
              *ngIf="form.controls['guardian_phone'].hasError('required') && form.controls['guardian_phone'].touched"
              class="error-message">This field is required.</div> -->
          </div>
        </div>

        <div class="fields two">
          <div class="field">
            <label>Issue Date
              <span class="rq-sign">*</span>
            </label>
            <div class="mixfeild">
              <input type="text" date="true" (click)="tpicker.open()" formControlName="card_issue_date"
                [matDatepicker]="tpicker" placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="tpicker"></mat-datepicker-toggle>
              <mat-datepicker #tpicker></mat-datepicker>
            </div>
            <div
              *ngIf="form.controls['card_issue_date'].hasError('required') && form.controls['card_issue_date'].touched"
              class="error-message">
              Date is required.
            </div>
          </div>

          <div class="field">
            <label>Expire Date
              <span class="rq-sign">*</span>
            </label>
            <div class="mixfeild">
              <input type="text" date="true" (click)="itpicker.open()" formControlName="card_expire_date"
                [matDatepicker]="itpicker" placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="itpicker"></mat-datepicker-toggle>
              <mat-datepicker #itpicker></mat-datepicker>
            </div>
            <div
              *ngIf="form.controls['card_expire_date'].hasError('required') && form.controls['card_expire_date'].touched"
              class="error-message">
              Date is required.
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="flex1"></div>
    <a mat-dialog-close class="enbutton cancel m-r16" (click)="dialogRef.close('no')">Cancel</a>
    <button type="submit" class="enbutton primary m-r16" [disabled]="!form.valid || process"
      (click)="create(form.value)">Update</button>
  </div>
  <app-spinner *ngIf="process"></app-spinner>
</form>
