import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { daysOfWeek } from 'src/app/core/dummy/stauts';
import { months, periodData } from 'src/app/core/dummy/report';
import { toDateKey } from './mapping.service';
import * as moment_tz from "moment-timezone";
@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor() { }

  static getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }

  static addDateMonth(date: any, interval: number) {
    return moment(date).add(interval, 'month').toDate()
  }

  static addDateDay(date: any, interval: number) {
    return moment(date).add(interval, 'days').toDate()
  }

  static fromPeriodToDate(dateString: string) {
    return moment(dateString, 'YYYY-MM-DD').toDate();
  }
  static toPeriodToDate(dateString: Date) {
    return moment(dateString).toDate();
  }

  static numberToDate(date: number) {
    return moment(date, 'YYYYMMDD').toDate();
  }


  static toDayKey(date: Date) {
    const dateKey = moment(date).format('DD');
    return dateKey.toString();
  }

  static toOnlyMonthKey(date: Date) {
    const dateKey = moment(date).format('MM');
    return dateKey.toString();
  }

  static getDateOfYearNew() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}1119`),
    };
  }
  static numberOnly(sender: any) {
    let numb = sender.match(/\d/g);
    numb = numb.join("");
    return this.toNumber(numb);
  }
  static addYear(interval: number) {
    return moment().add(interval, 'year').toDate()
  }
  static toNumber(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value);
  }
  static toYear(date: Date) {
    return moment(date).format('YYYY');
  }
  static getDaySchedule(days) {
    var daysInput = Object.keys(days).map(function (key) {
      return { name: key, value: days[key] };
    });

    daysInput = daysInput.filter(m => m.value === true)
    daysInput.sort((a: any, b: any) => {
      var day1 = a.name
      var day2 = b.name
      if (daysOfWeek[day1] < daysOfWeek[day2]) {
        return -1;
      } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
        return 1;
      } else {
        return 0;
      }
    });
    let result = '';
    daysInput.forEach(item => {
      if (item.name === 'monday')
        result = result + 'Monday / '
      else if (item.name.toLowerCase() === 'tuesday')
        result = result + 'Tuesday / '
      else if (item.name.toLowerCase() === 'friday')
        result = result + 'Friday / '
      else if (item.name.toLowerCase() === 'saturday')
        result = result + 'Saturday / '
      else if (item.name.toLowerCase() === 'sunday')
        result = result + 'Sunday / '
      else if (item.name.toLowerCase() === 'thursday')
        result = result + 'Thursday / '
      else if (item.name.toLowerCase() === 'day1')
        result = result + 'Day 1 / '
      else if (item.name.toLowerCase() === 'day2')
        result = result + 'Day 2 / '
      else
        result = result + 'Wednesday / '
    })
    if (result.length > 3)
      result = result.substring(0, result.length - 3)
    return result;
  }
  static toFloatFixed(value, fix) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(fix));
  }

  static toCapitalize(value) {
    let string = null;
    if (value) string = value.toUpperCase().toString().trim();
    return string;
  }

  static getSessionDayTrue(session) {
    let result = [];
    if (session && session.days) {
      let daysInput = Object.keys(session.days).map(function (key) {
        return { name: key, value: session.days[key] };
      });

      result = daysInput.filter(m => m.value === true);
    }
    return result;
  }

  static getNumber(value: any, getval: any): number {
    if (value === NaN || value === null || value === undefined || value === '') {
      return getval;
    }
    return Number(value);
  }

  static toNull(value) {
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputValue(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputKey(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value.key;
  }

  static yearSuffix() {
    return moment().format('YY').toString();
  }
  static addWeek(interval: number) {
    return moment().add(interval, 'week').toDate()
  }

  static addDays(interval: number) {
    return moment().add(interval, 'days').toDate()
  }

  static addMonth(interval: number) {
    return moment().add(interval, 'month').toDate()
  }

  static pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  static toDateKey(date: Date) {
    return Number(moment(date).format('YYYYMMDD'))
  }

  static dateKey() {
    return Number(moment().format('YYYYMMDD'))
  }
  static toFloatFixed2(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(2));
  }
  static hourToNumber(time) {
    return Number(moment(time, 'hh:mm a').format('HH').toString() + '.' + moment(time, 'hh:mm a').format('mm').toString());
  }

  static toHour(time) {
    return moment(time, 'hh:mm a').format('hh:mm a')
  }

  static generate_testing_invoiceNo(config) {
    var number = moment().format('YYMMDD').toString() + (config.invoice_shufit + 1).toString();
    return Number(number);
  }

  static generate_puc_id(config) {
    var number = moment().format('YY').toString() + (config.puc_id + 1).toString();
    return number;
  }

  static getDefaultDateReport(day: number) {
    const yearMonth = moment(new Date).format('YYYYMM');
    const yearMonthDay = `${yearMonth}${day}`;
    const toDate = moment(yearMonthDay).toDate();
    return {
      form_date: this.toDateSting(moment(toDate).subtract(3, 'months').add(1, 'days')),
      to_date: this.toDateSting(toDate),
    };
  }

  static getDateOfMonth(day: number, months) {
    const yearMonthFrom = moment(new Date).subtract(months, 'months').format('YYYYMM');
    const yearMonthDay = `${yearMonthFrom}${day + 1}`;
    const yearMonth = moment(new Date).format('YYYYMM');
    const toDate = moment(`${yearMonth}${day}`).toDate();
    return {
      form_date: this.toDateSting(yearMonthDay),
      to_date: this.toDateSting(toDate),
    };
  }

  static getDateOfYear(day: number) {
    const lastYearNumber = toNumber(moment(new Date).subtract(1, 'years').format('YYYY'))
    const yearMonth = moment(new Date).format('YYYYMM');
    return {
      form_date: this.toDateSting(moment(`${lastYearNumber}12${day + 1}`, 'YYYYMMDD').toDate()),
      to_date: this.toDateSting(moment(`${yearMonth}${day}`, 'YYYYMMDD').toDate()),
    };
  }

  static getDateCurrentMonth() {
    const current = moment(new Date()).format('YYYYMM')
    const last = toNumber(moment(new Date()).subtract(1, 'months').format('YYYYMM'))
    return {
      form_date: toNumber(`${last}20`),
      to_date: toNumber(`${current}19`),
    };
  }

  static getDateFirstQuarter() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0219`),
    };
  }

  static getDateSecondQuarter() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current;
    return {
      form_date: toNumber(`${last}0220`),
      to_date: toNumber(`${current}0519`),
    };
  }

  static getDateFirstSemester() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0519`),
    };
  }

  static getDateSecondSemester() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current;
    return {
      form_date: toNumber(`${last}0520`),
      to_date: toNumber(`${current}1119`),
    };
  }

  static getDateNineMonth() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0819`),
    };
  }



  static getDateOfLastYearNew() {
    const current = toNumber(moment(new Date()).subtract(1, 'years').format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}1119`),
    };
  }

  static getCurrentYear() {
    const year = moment(new Date).format('YYYY');
    return year;
  }

  static toDateSting(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }
  static getDateOfLastYear(day: number) {
    const lastYearNumber = toNumber(moment(new Date).subtract(2, 'years').format('YYYY'))
    const endLastYear = moment(new Date).subtract(1, 'years').endOf('years').format('YYYYMM');
    return {
      form_date: this.toDateSting(moment(`${lastYearNumber}12${day + 1}`, 'YYYYMMDD').toDate()),
      to_date: this.toDateSting(moment(`${endLastYear}${day}`, 'YYYYMMDD').toDate()),
    };
  }

  static getDateCompareOfLastYear(day: number) {
    const lastYearNumber = toNumber(moment(new Date).subtract(3, 'years').format('YYYY'))
    const endLastYear = moment(new Date).subtract(1, 'years').endOf('years').format('YYYYMM');
    return {
      form_date: this.toDateSting(moment(`${lastYearNumber}12${day + 1}`, 'YYYYMMDD').toDate()),
      to_date: this.toDateSting(moment(`${endLastYear}${day}`, 'YYYYMMDD').toDate()),
    };
  }

}

export function toYearMonthKey(date: Date) {
  return Number(moment(date).format('YYYYMM'))
}

export function toUpperCase(value: string) {
  if (value === "" || value === undefined) {
    return null;
  }
  return value.toUpperCase();
}

export function sum(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + toNumber(b[field]), 0)
}

export function toNumber(value) {
  if (value === null || value === "" || value === undefined) {
    return 0;
  }
  if (Number(value) === NaN) return 0;
  return Number(value);
}

export const getDatesBetweenDates = (startDate, endDate) => {
  let dates = []
  //to avoid modifying the original date
  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, endDate]
  return dates
}
export const getDatesBetweenDatesSame = (startDate, endDate) => {
  const fromdate = Number(moment(startDate).format('YYYYMMDD'));
  const todate = Number(moment(endDate).format('YYYYMMDD'));
  const isSame = fromdate === todate;
  return isSame
}
export const getMonthName = (date) => {
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let monthName = months[date.getMonth()];
  return monthName
}
export const getDatesKeyBetweenDates = (startDate, endDate) => {
  let dates = []
  //to avoid modifying the original date
  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, endDate]
  const data = dates.map(f => toDateKey(f))
  const newData = [...new Set(data)]
  return newData
}

export const getDatesDayBetweenDates = (startDate, endDate) => {
  let dates = []
  let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  //to avoid modifying the original date
  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, endDate]
  const data = dates.map(f => (days[f.getDay()]))
  const newData = [...new Set(data)]
  return newData
}

export const getDayName = (date) => {
  let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const data = days[date.getDay()]
  return data
}
export function toMonthKey(date: Date) {
  return Number(moment(date).format('YYYYMM'))
}
export const createKeywords = name => {
  const arrName = [];
  let curName = '';
  let nextName = '';
  name.trim().split(/[ .\-_ ]/).forEach(letter => {
    curName += letter;
    arrName.push(ConvertService.toCapitalize(letter));
    arrName.push(ConvertService.toCapitalize(curName));
  });

  name.split('').forEach(letter => {
    nextName += letter;
    arrName.push(ConvertService.toCapitalize(nextName));
  });

  return arrName;
}

export const generateKeywords = names => {
  const [keyword1, keyword2, keyword3, keyword4, keyword5, keyword6, keyword7, keyword8, keyword9, keyword10] = names;
  const suffix1 = keyword1 && keyword1.length > 0 ? `${keyword1}` : '';
  const suffix2 = keyword2 && keyword2.length > 0 ? `${keyword2}` : '';
  const suffix3 = keyword3 && keyword3.length > 0 ? `${keyword3}` : '';
  const suffix4 = keyword4 && keyword4.length > 0 ? `${keyword4}` : '';
  const suffix5 = keyword5 && keyword5.length > 0 ? `${keyword5}` : '';
  const suffix6 = keyword6 && keyword6.length > 0 ? `${keyword6}` : '';
  const suffix7 = keyword7 && keyword7.length > 0 ? `${keyword7}` : '';
  const suffix8 = keyword8 && keyword8.length > 0 ? `${keyword8}` : '';
  const suffix9 = keyword9 && keyword9.length > 0 ? `${keyword9}` : '';
  const suffix10 = keyword10 && keyword10.length > 0 ? `${keyword10}` : '';
  const keywordT1 = createKeywords(`${suffix1}`);
  const keywordT2 = createKeywords(`${suffix2}`);
  const keywordT3 = createKeywords(`${suffix3}`);
  const keywordT4 = createKeywords(`${suffix4}`);
  const keywordT5 = createKeywords(`${suffix5}`);
  const keywordT6 = createKeywords(`${suffix6}`);
  const keywordT7 = createKeywords(`${suffix7}`);
  const keywordT8 = createKeywords(`${suffix8}`);
  const keywordT9 = createKeywords(`${suffix9}`);
  const keywordT10 = createKeywords(`${suffix10}`);
  return [
    ...new Set([
      '~N/A~',
      ...keywordT1,
      ...keywordT2,
      ...keywordT3,
      ...keywordT4,
      ...keywordT5,
      ...keywordT6,
      ...keywordT7,
      ...keywordT8,
      ...keywordT9,
      ...keywordT10,
    ])
  ];
}


export function toMonthKh(date: any) {
  const index = moment(date).format("MM");
  return months[index].name
}

export function toMonthNum(date: Date) {
  const index = moment(date).format("MM");
  return months[index].key
}


export function toDayOfMonth(date: any) {
  const index = moment(date).format("DD");
  return index;
}

export function toHourMM(date: Date) {
  return Number(moment_tz(date).tz('Asia/Phnom_Penh').format('HH.mm'))
}
export function swhichLabel(gdata) {
  if(!gdata) return
  const { remark } = gdata;
  if (!remark) return
  switch (remark.name) {
    case 'OK':
      return 'C'
    case 'P':
      return 'P'
    default:
      return remark.name
  }
}
export function swhichRemarkLabel(remark) {
  if (!remark) return
  switch (remark.name) {
    case 'OK':
      return 'C'
    case 'P':
      return 'P'
    default:
      return remark.name
  }
}

export function getTimePolicy(hour: number) {
  let value = null
  if (hour > 1 && hour <= 12) value = { fromHour: 0, toHour: 12 };
  else if (hour > 12 && hour <= 17.30) value = { fromHour: 12, toHour: 17 };
  else if (hour > 17.30 && hour <= 24) value = { fromHour: 17, toHour: 24 };
  else value = { fromHour: 17, toHour: 24 };
  return value;
}

export function toMonthOfYear(date: Date) {
  const index = moment(date).format("MM");
  return index;
}

export function toYearOfPeriod(date: any) {
  const index = moment(date).format("YYYY");
  return index;
}

export function getDateReport(optionKey) {
  const items = periodData.filter(m => m.key === optionKey)[0];
  switch (toNumber(items.key)) {
    case 1:
      const fromDate1 = ConvertService.getDateCurrentMonth().form_date
      const toDate1 = ConvertService.getDateCurrentMonth().to_date
      return {
        fromDate: fromDate1,
        toDate: toDate1
      }
    case 2:
      const fromDate2 = ConvertService.getDateFirstQuarter().form_date
      const toDate2 = ConvertService.getDateFirstQuarter().to_date
      return {
        fromDate: fromDate2,
        toDate: toDate2
      }
    case 3:
      const fromDate3 = ConvertService.getDateSecondQuarter().form_date
      const toDate3 = ConvertService.getDateSecondQuarter().to_date
      return {
        fromDate: fromDate3,
        toDate: toDate3
      }
    case 4:
      const fromDate4 = ConvertService.getDateFirstSemester().form_date
      const toDate4 = ConvertService.getDateFirstSemester().to_date
      return {
        fromDate: fromDate4,
        toDate: toDate4
      }
    case 5:
      const fromDate5 = ConvertService.getDateSecondSemester().form_date
      const toDate5 = ConvertService.getDateSecondSemester().to_date
      return {
        fromDate: fromDate5,
        toDate: toDate5
      }
    case 6:
      const fromDate6 = ConvertService.getDateNineMonth().form_date
      const toDate6 = ConvertService.getDateNineMonth().to_date
      return {
        fromDate: fromDate6,
        toDate: toDate6
      }
    case 7:
      const fromDate7 = ConvertService.getDateOfYearNew().form_date
      const toDate7 = ConvertService.getDateOfYearNew().to_date
      return {
        fromDate: fromDate7,
        toDate: toDate7
      }
    case 8:
      const fromDate8 = ConvertService.getDateOfLastYearNew().form_date
      const toDate8 = ConvertService.getDateOfLastYearNew().to_date
      return {
        fromDate: fromDate8,
        toDate: toDate8
      }
    default:
      break;
  }

}

export function toNullFromZero(value) {
  if (value === null || value === "" || value === undefined || value === 0) {
    return null;
  }
  return value;
}


export function monthOfDate(date: Date) {
  return moment(date).format('YYYYMM');
}

export function lastMonthOfDate(date: Date) {
  return moment(date).subtract(1, 'month').format('YYYYMM');
}

export function formatMoney(amount: any, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i: any = parseInt((amount = Math.abs(toNumber(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '1' + thousands) +
      (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
    );
  } catch (e) {
  }
}


export class EwisAddmissionStudent {
  constructor(
    readonly key: string,
    readonly grade: string,
    readonly academicYearKey: string,
    readonly academicYear: string,
    readonly campus: string,
    readonly campusKey: string,
    readonly full_name: string,
    readonly studentKey: string,
    readonly puc_id: string,
    readonly gender: string,
    readonly school: string,
    readonly schoolKey: string,
  ) { }

  toString(): string {
    return null
  }
}


export const EwisAddmissionStudentConverterDetail = {
  toFirestore(stock: EwisAddmissionStudent): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      grade: stock.grade,
      academicYearKey: stock.academicYearKey,
      academicYear: stock.academicYear,
      campus: stock.campus,
      campusKey: stock.campusKey,
      full_name: stock.full_name,
      studentKey: stock.studentKey,
      puc_id: stock.puc_id,
      gender: stock.gender,
      school: stock.school,
      schoolKey: stock.schoolKey,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): EwisAddmissionStudent {
    const data = snapshot.data(options)!;
    return new EwisAddmissionStudent(
      data.key,
      data.program_academic.name,
      data.academicYearKey,
      data.academicYear.name,
      data.campus.name,
      data.campusKey,
      data.student.full_name,
      data.student.key,
      data.student.puc_id,
      data.student.gender.key,
      data.school.name,
      data.schoolKey,
    );
  }
};
