import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PHOTO_LIB } from 'src/app/core/dummy/data';
import { Enrollment } from 'src/app/core/stores/enrollment.store';
import { ConvertService } from 'src/app/shared/services/convert.service';

@Component({
  selector: 'app-student-card-form',
  templateUrl: './student-card-form.component.html',
  styleUrls: ['./student-card-form.component.scss']
})
export class StudentCardFormComponent implements OnInit {
  form: FormGroup;
  URL: any = PHOTO_LIB.EMPTY;
  SELECTED_PHOTO: any = null;
  process = false
  @ViewChild('photoFile',) photoFile: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<StudentCardFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public store: Enrollment,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      guardian_name: [null],
      guardian_phone: [null,],
      khmer_first_name: [null, Validators.required],
      khmer_last_name: [null, Validators.required],
      dob: [null, Validators.required],
      card_issue_date: [null, Validators.required],
      card_expire_date: [null, Validators.required],
    })

    if (this.data) {

      this.form.patchValue({
        first_name: this.data.item.first_name,
        last_name: this.data.item.last_name,
        khmer_first_name: this.data.item.khmer_first_name,
        khmer_last_name: this.data.item.khmer_last_name,
        guardian_name: this.data.item.guardian_name,
        guardian_phone: this.data.item.guardian_phone,
        dob: this.data.item && this.data.item.dob ? this.data.item.dob.toDate() : new Date(),
        card_issue_date: this.data.item && this.data.item.card_issue_date ? this.data.item.card_issue_date.toDate() : new Date(),
        card_expire_date: this.data.item && this.data.item.card_expire_date ? this.data.item.card_expire_date.toDate() : new Date(),
      })
    }
  }

  onSelectedFile(event) {
    if (event && event.target.files.length > 0) {
      this.SELECTED_PHOTO = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.URL = event.target.result;
      }
      this.photoFile.nativeElement.value = "";
    }
  }

  create(f: any) {
    if (this.form.valid) {
      const item: any = {
        key: this.data.item.key,
        first_name: f.first_name,
        last_name: f.last_name,
        full_name: f.last_name + ' ' + f.first_name,
        khmer_first_name: f.khmer_first_name,
        khmer_last_name: f.khmer_last_name,
        khmer_full_name: f.khmer_last_name + ' ' + f.khmer_first_name,
        guardian_name: f.guardian_name,
        guardian_phone: f.guardian_phone,
        dob: f.dob,
        dobKey: ConvertService.toDateKey(f.dob),
        card_expire_date: f.card_expire_date,
        card_issue_date: f.card_issue_date,
      };
      this.store.addNew(this.data.collection, item, this.SELECTED_PHOTO, this.data ? this.data.filename : null, (success, error) => {
        if (success) {
          this.snackBar.open("Card has been updated.", "done", {
            duration: 2000
          });
          this.form.reset();
          this.dialogRef.close()
        } else {
          alert(error);
        }
      });
    }
  }


}
