import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value) return;
    const date = new Date(value);
    return new DatePipe('en-US').transform(date, 'dd. MMM yyyy, hh:MM:ss a');
  }

}
