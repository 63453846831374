import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

import { AuthGuard } from './core/guards/auth.guard';
import { SecureInnerPagesGuard } from './core/guards/secure-inner-pages.guard';
import { CampusPanelComponent } from './layouts/campus-panel/campus-panel.component';
import { ShiftReportComponent } from './layouts/shift-report/shift-report.component';
import { StudentPanelComponent } from './layouts/student-panel/student-panel.component';
import { OverviewLayoutComponent } from './layouts/overview-layout/overview-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { CashierPanelComponent } from './layouts/cashier-panel/cashier-panel.component';

const routes: Routes = [
  {
    path: 'auth', canActivate: [SecureInnerPagesGuard],
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: '',
    canActivate: [AuthGuard], component: OverviewLayoutComponent,
    loadChildren: () => import('./modules/overview/overview.module').then(m => m.OverviewModule)
  },

  {
    path: 'human-resource',
    canActivate: [AuthGuard], component: MainLayoutComponent,
    loadChildren: () => import('./modules/human-resource/hr.module').then(m => m.HRModule)
  },

  {
    path: 'admin',
    canActivate: [AuthGuard], component: MainLayoutComponent,
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },







  // Fallback when no prior routes is matched
  // { path: 'page-not-found', component: PageNotFoundComponent },
  // { path: '**', redirectTo: '/page-not-found', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
