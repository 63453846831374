<aside class="sidebar">
  <div class="sidebar-brand" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
    <div class="sbar-brand-name">
      {{SCHOOL.name}}
    </div>
  </div>
  <div class="sidebar-header">
    <div class="header-content">
      <div class="list-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
        <div class="icon">
          <i class="material-icons">home</i>
        </div>
        <div class="item-name">
          Home
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar-list-wrapper">
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="expansions.four" (closed)="onClosed(4)" (opened)="onOpened(4)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.four">
          <mat-panel-title>
            Administrator
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.four">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/attendence/student']">
              <div class="icon">
                <i class="material-icons">account_circle</i>
              </div>
              <div class="item-name">
                Student
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/attendence/permission']">
              <div class="icon">
                <i class="material-icons">library_books</i>
              </div>
              <div class="item-name">
                Permission Lists
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/attendence/confirm-student-attendance']">
              <div class="icon">
                <i class="material-icons">code</i>
              </div>
              <div class="item-name">
                Confirm Attendance
              </div>
            </div>

          </div>
        </div>

      </mat-expansion-panel>


      <mat-expansion-panel [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.two">
          <mat-panel-title>
            Print QR Code
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.two">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/students-qrcode']">
              <div class="icon">
                <i class="material-icons">search</i>
              </div>
              <div class="item-name">
                Search Student QR Code
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/parents-qrcode']">
              <div class="icon">
                <i class="material-icons">search</i>
              </div>
              <div class="item-name">
                Search Parent QR Code
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/grade-qrcode']">
              <div class="icon">
                <i class="material-icons">center_focus_weak</i>
              </div>
              <div class="item-name">
                Student QR Code </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/parent-grade-qrcode']">
              <div class="icon">
                <i class="material-icons">center_focus_weak</i>
              </div>
              <div class="item-name">
                Parent QR Code </div>
            </div>


          </div>
        </div>

      </mat-expansion-panel>
      <!--
      <mat-expansion-panel [expanded]="expansions.three" (closed)="onClosed(3)" (opened)="onOpened(3)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.three">
          <mat-panel-title>
            Card
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.three">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/student/batch']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Batch Student Card
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/student/card']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Student Card
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/instructor/card']">
              <div class="icon">
                <i class="material-icons">recent_actors</i>
              </div>
              <div class="item-name">
                Instructor Card
              </div>
            </div>




          </div>
        </div>

      </mat-expansion-panel> -->
      <mat-expansion-panel [expanded]="expansions.one" (closed)="onClosed(1)" (opened)="onOpened(1)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.one">
          <mat-panel-title>
            Reports
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.one">Listing and Summary English Program Reports.
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <a class="list-item-row" routerLinkActive="active" [routerLink]="['/admin/reports/student-attendance']">
              <div class="icon">
                <i class="material-icons">account_circle</i>
              </div>
              <div class="item-name">
                Student Listing
              </div>
            </a>
          </div>
          <div class="list-item">
            <a class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/reports/student-attendance-report']">
              <div class="icon">
                <i class="material-icons">account_circle</i>
              </div>
              <div class="item-name">
                Attendance Report
              </div>
            </a>
          </div>
          <!-- <div class="list-item">
            <a class="list-item-row" routerLinkActive="active"
              [routerLink]="['/admin/reports/student-attendance-absent']">
              <div class="icon">
                <i class="material-icons">account_circle</i>
              </div>
              <div class="item-name">
                Daily Absent Listing
              </div>
            </a>
          </div> -->
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="sidebar-footer">
    <div class="sidebar-toggle-button" (click)="onToggleSidebar()">
      <div class="arrow-icon"></div>
    </div>
  </div>
</aside>
