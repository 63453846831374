// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyAt4Pda8mHC4qQn1nLgYrFkZCvrvVJEL4A",
    // authDomain: "ngscloud-v2.firebaseapp.com",
    // databaseURL: "https://ngscloud-v2.firebaseio.com",
    // projectId: "ngscloud-v2",
    // storageBucket: "ngscloud-v2.appspot.com",
    // messagingSenderId: "589897378302",
    // appId: "1:589897378302:web:b101d6aa43e6b50f26f8af",
    // measurementId: "G-P2P9TEG3G0"

    apiKey: "AIzaSyDH3eFolE9HUmNDbw3qN9GYrhjEhHiw0Po",
    authDomain: "spscloud-e9e6a.firebaseapp.com",
    databaseURL: "https://spscloud-e9e6a.firebaseio.com",
    projectId: "spscloud-e9e6a",
    storageBucket: "spscloud-e9e6a.appspot.com",
    messagingSenderId: "955845920778",
    appId: "1:955845920778:web:6ca737a7eb51a1cdda77f3",
    measurementId: "G-E20F4P1T1C"

    // apiKey: "AIzaSyB6aJQ0p_fnkle1UKlK_JOiUZCseMRyxEM",
    // authDomain: "ngscloudv3.firebaseapp.com",
    // databaseURL: "https://ngscloudv3-default-rtdb.firebaseio.com",
    // projectId: "ngscloudv3",
    // storageBucket: "ngscloudv3.appspot.com",
    // messagingSenderId: "759124229487",
    // appId: "1:759124229487:web:3de8b197e919e20c43fc9a",
    // measurementId: "G-DL2F6H6DTD"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
