import { invoiceTypesObj, paymentStatus, enrollCourseStatus, enrollStatusObj } from './../dummy/stauts';
import { DataService } from "./../services/data.service";
import { observable, action } from "mobx";
import { Injectable } from "@angular/core";
import { ConvertService, toYearMonthKey } from 'src/app/shared/services/convert.service';
import { MappingService, pushToArray, pushToObject, toDateKey } from 'src/app/shared/services/mapping.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class Enrollment {
  @observable public data = null;
  @observable public loading = false;
  @observable public empty = false;
  @observable public done = false;
  @observable public fetching = false;
  // @observable public student = null;
  @observable public process = false;
  @observable public dataSources = [];
  @observable public englishInvoices = [];
  @observable public englishInvoiceDetail = [];
  @observable public total = 0;

  @observable public batchMembers = [];
  @observable public level = [];
  @observable public institutes = [];
  @observable public campus = [];
  @observable public programs = [];
  @observable public shifts = [];
  @observable public testingStudents = [];
  @observable public comingStudents = [];
  @observable public selectedCampus = null;
  @observable public selectedShift = null;
  @observable public selectedInstitute = null;
  @observable public selectedLevel = null;
  @observable public filterType = null;
  @observable public schedules = null;
  @observable public statisticAttendence = null;
  @observable public attedenceStudents = [];
  @observable public tattedenceStudents = [];
  @observable public permissionsStudents = [];
  uploads: any[];
  allPercentage: Observable<any>;


  public lastVisible: any = new Date();
  constructor(private ds: DataService, private storage: AngularFireStorage,
  ) { }

  @action
  deleteFile(collection, filename) {
    const ext = filename.split('.').pop()
    const name = filename.split('.').slice(0, -1).join('.')
    const fileRef = this.storage.ref(collection + '/' + filename);
    fileRef.delete()
    // const fileArray = this.filesize.map(f => ({ name: name + '_' + f.size + '.' + ext }))
    // for (const file of fileArray) {
    //   const resizeRef = this.storage.ref(collection + '/thumbs/' + file.name);
    //   resizeRef.delete()
    // }
  }

  @action
  addNew(collection, item: any, filelist, oldfile, callback) {
    this.process = true;
    if (oldfile && filelist) {
      this.deleteFile('card_photo', oldfile)
    }
    if (filelist) {
      this.process = true;
      this.uploads = [];
      const allPercentage: Observable<number>[] = [];
      const filename = Math.random().toString(36).substring(7) + new Date().getTime() + filelist.name;
      const path = `card_photo/${filename}`;
      const task = this.storage.upload(path, filelist);
      const _percentage$: any = task.percentageChanges();
      allPercentage.push(_percentage$);
      const uploadTrack = {
        fileName: filename,
        percentage: _percentage$
      }
      this.uploads.push(uploadTrack);
      const _t = task.then((f) => {
        return f.ref.getDownloadURL().then((url) => {
          const data = {
            ...item,
            card_filename: filename,
            card_fileurl: url
          }
          this.ds.collection(collection).doc(item.key).update(data).then(() => {
            callback(true, null);
            this.process = false;
          }).catch(error => {
            callback(true, error);
            this.process = false;
          });
        })
      })
      this.allPercentage = combineLatest(allPercentage)
        .pipe(
          map((percentages) => {
            let result = 0;
            for (const percentage of percentages) {
              result = result + percentage;
            }
            return result / percentages.length;
          }),
          tap(console.log)
        );
    } else {
      this.ds.collection(collection).doc(item.key).update(item).then(() => {
        callback(true, null);
        this.process = false;
      }).catch(error => {
        callback(true, error);
        this.process = false;
      });
    }
  }



  @action
  updateStudentInfo(collection, item: any, selectBatch, studentbatch, filelist, oldfile, callback) {
    this.process = true;
    const batch = this.ds.batch();
    if (oldfile && filelist) {
      this.deleteFile('card_photo', oldfile)
    }
    if (filelist) {
      this.process = true;
      this.uploads = [];
      const allPercentage: Observable<number>[] = [];
      const filename = Math.random().toString(36).substring(7) + new Date().getTime() + filelist.name;
      const path = `card_photo/${filename}`;
      const task = this.storage.upload(path, filelist);
      const _percentage$: any = task.percentageChanges();
      allPercentage.push(_percentage$);
      const uploadTrack = {
        fileName: filename,
        percentage: _percentage$
      }
      this.uploads.push(uploadTrack);
      const _t = task.then((f) => {
        return f.ref.getDownloadURL().then((url) => {
          const data = {
            ...item,
            card_filename: filename,
            card_fileurl: url
          }
          this.ds.collection(collection).doc(item.key).update(data).then(() => {
            callback(true, null);
            this.process = false;
          }).catch(error => {
            callback(true, error);
            this.process = false;
          });
        })
      })
      this.allPercentage = combineLatest(allPercentage)
        .pipe(
          map((percentages) => {
            let result = 0;
            for (const percentage of percentages) {
              result = result + percentage;
            }
            return result / percentages.length;
          }),
          tap(console.log)
        );
    } else {
      const student = this.ds.firestore().collection('students')
      const studentBatch = this.ds.firestore().collection('institute_training_level_batch').doc(selectBatch.key).collection('students')
      batch.update(student.doc(item.key), item);
      batch.update(studentBatch.doc(studentbatch.key), {
        student: { ...studentbatch.student, ...item },
      });

      batch.commit().then(() => {
        this.process = false;
        callback(true, null);
      }).catch(error => {
        this.process = false;
        callback(false, error);
      })

      // this.ds.collection(collection).doc(item.key).update(item).then(() => {
      //   callback(true, null);
      //   this.process = false;
      // }).catch(error => {
      //   callback(true, error);
      //   this.process = false;
      // });
    }
  }
  @action
  fetchbatchstudentData(key, callback?) {
    this.loading = true;
    this.done = false;
    this.data = [];
    this.ds.batchstudentRef(key).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.lastVisible = docs[docs.length - 1];
      } else {
        this.lastVisible = null;
        this.done = true;
      }
      this.empty = docs.length === 0;
      let data = docs
      // this.data = docs;
      if (data && data.length > 0) {
        Promise.resolve(
          docs.map(async m => {
            const { student } = m
            const studentDocs = await this.ds.studentAccID(student.puc_id).get().toPromise()
            const studentData = MappingService.pushToArray(studentDocs)
            if (studentData && studentData.length > 0) {
              const data = {
                ...m,
                studentData: studentData[0]
              }
              this.data.push(data)
            }
          })
        )
      }
      this.loading = false;

      callback && callback(this.data)
    });
  }


  @action
  async fetchSearchBatchLevel(schoolKey, yearKey, campusKey, field: string, search: any, callback) {
    return this.ds.autoSearchBatchLevelRef(field, search, schoolKey, yearKey, campusKey).valueChanges().subscribe(docs => {
      callback(docs)
    });
  }

  @action
  async fetchSearchGradeLevel(schoolKey, yearKey, campusKey, field: string, search: any, callback) {
    return this.ds.autoSearchBatchLevelRef(field, search, schoolKey, yearKey, campusKey).valueChanges().subscribe(docs => {
      callback(docs)
    });
  }

  @action
  async fetchAttendenceStatistic(administionKey, studentKey, date) {
    this.loading = true;
    this.done = false;
    const dateKey = toDateKey(date);
    const tattdata = pushToArray(await this.ds.collection('students').doc(studentKey).collection('time_attendance', ref => ref.where('scan_date_key', '==', dateKey)).get().toPromise());
    this.tattedenceStudents = MappingService.orderBy(tattdata, "create_date");
    const attdata = pushToArray(await this.ds.collection('students').doc(studentKey).collection('student_attendance_movement', ref => ref.where('checkIn_date_key', '==', dateKey)).get().toPromise());
    this.attedenceStudents = attdata;
    const dateStr = toYearMonthKey(date);
    const data = pushToObject(await this.ds.collectionSAttendence(administionKey, studentKey, dateStr).get().toPromise());
    this.statisticAttendence = data;
    this.loading = false;
  }


  @action
  async fetchStudentAttendenceStatisticBetweenDate(administionKey, studentKey, fromDate, toDate) {
    this.loading = true;
    this.done = false;
    const from_Date = moment(fromDate).startOf("day").toDate();
    const to_Date = moment(toDate).endOf("day").toDate();
    // const dateKey = toDateKey(date);
    const data = await this.ds.collection('students').doc(studentKey).collection("time_attendance", ref => ref.where("create_date", ">=", from_Date)
      .where("create_date", "<=", to_Date)).get().toPromise()
    this.attedenceStudents = pushToArray(data)

    console.log('this.attedenceStudents', this.attedenceStudents)

    this.loading = false;
  }

  @action
  async fetchStudentPermission(studentKey: string) {
    this.loading = true;
    this.ds.collection('students').doc(studentKey).collection('permissions').valueChanges().subscribe(attdata => {
      this.permissionsStudents = attdata;
      this.loading = false;

    })
  }

  // @action
  // searchStudent(schoolKey, field, searchText) {
  //   return this.ds.searchStudentRef(schoolKey, field, searchText).valueChanges();
  // }
  // @action
  // searchStudent(schoolKey, field, search, callback?) {
  //   this.done = false;
  //   console.log('selectedSchool.key, searchType.key,search', schoolKey, field, search);

  //   this.ds.searchStudentRef(schoolKey, field, search).valueChanges().subscribe(docs => {

  //     console.log('docs', docs);

  //     if (docs.length) {
  //       this.lastVisible = docs[docs.length - 1];
  //     } else {
  //       this.lastVisible = null;
  //       this.done = true;
  //     }
  //     this.empty = docs.length === 0;
  //     this.data = docs;
  //     console.log('student', this.data);

  //     callback && callback(docs)
  //   });
  // }

  @action
  searchStudent(schoolKey, field, search) {
    if (search && search.key) {
      return this.ds.searchStudentRef(schoolKey, field, search.puc_id).valueChanges();
    }
    return this.ds.searchStudentRef(schoolKey, field, search).valueChanges();
  }

  @action
  searchInstructor(schoolKey, field, search) {
    if (search && search.key) {
      return this.ds.searchInstructorRef(schoolKey, field, search.puc_id).valueChanges();
    }
    return this.ds.searchInstructorRef(schoolKey, field, search).valueChanges();
  }
  @action
  fetchStudentData(key, callback?) {
    this.loading = true;
    this.done = false;
    this.ds.studentKeyRef(key).valueChanges().subscribe(docs => {
      if (docs.length) {
        this.lastVisible = docs[docs.length - 1];
      } else {
        this.lastVisible = null;
        this.done = true;
      }
      this.empty = docs.length === 0;
      this.data = docs;
      this.loading = false;
      callback && callback(docs)
    });
  }
  @action
  updateChildAccount(parent, myChild, callback) {
    this.process = true;
    this.ds.parentsRef().doc(parent.key).update({
      children: myChild || []
    }).then(() => {
      this.process = false;
      callback(true, null);
    }).catch(error => {
      this.process = false;
      callback(false, error);
    })
  }

  @action
  async resetStudentPassword(item: any, user: any, callback) {
    this.process = true;
    this.ds.studentAccountDocRef(item.key).update({
      resetPassword: true,
      resetPassword_date: new Date(),
      resetPassword_date_key: ConvertService.dateKey(),
      resetPassword_by: MappingService.userObj(user),
    }).then(async () => {

      const studentAccount = await this.ds.studentDocument(item.key).get().toPromise();
      const studentData = MappingService.pushToObject(studentAccount);
      this.process = false;
      callback(true, studentData)
    }).catch(error => {
      this.process = false;
      callback(false, error)
    })
  }

  @action
  fetchBatchLevel(callback) {
    this.process = true;
    this.ds.batchLevelShiftRef(this.selectedLevel.key, this.selectedShift.key, this.selectedCampus.key).valueChanges().subscribe(docs => {
      callback(docs);
      this.process = false;
    })
  }

  @action
  fetchStudentSchedules(termKey: string, studentKey: string) {
    this.loading = true;
    this.schedules = null;
    this.ds.studentCurrentScheduleTermRef(termKey, studentKey).valueChanges().subscribe(docs => {
      this.schedules = docs;
      this.loading = false;
    })
  }

  @action
  fetchCampus(campus) {
    this.testingStudents = [];
    this.comingStudents = [];
    this.data = [];
    this.selectedLevel = null;
    this.selectedCampus = campus;
  }

  @action
  fetchProgram() {
    this.testingStudents = [];
    this.comingStudents = [];
    this.data = [];
    this.selectedLevel = null;
  }

  @action
  async fetchStudentEnrollment(studentKey: string, termKey: string, callback) {
    this.process = true;
    const enrollmentDocs = await this.ds.studentDocument(studentKey).collection("enrollment", ref => ref.where("term.key", "==", termKey)).get().toPromise();
    const enrollmentData = MappingService.pushToArray(enrollmentDocs);
    this.process = false;
    callback(enrollmentData)
  }

  @action
  async fetchNewStudents(level) {
    this.loading = true;
    this.selectedLevel = level;
    const termDoc = await this.ds.paymentTermRef(this.selectedInstitute.key).get().toPromise();
    const term = MappingService.pushToObject(termDoc);
    const testingStudentDocs = await this.ds.scheduleInProgressRef(
      this.selectedInstitute.key, this.selectedShift.key, this.selectedCampus.key, term.key, level.key).get().toPromise();
    const comingStudentsDocs = await this.ds.studentInstituteNoScheduleRef(
      this.selectedInstitute.key, this.selectedShift.key, this.selectedCampus.key, term.key, level.key).get().toPromise();
    this.comingStudents = MappingService.pushToArray(comingStudentsDocs);
    this.testingStudents = MappingService.pushToArray(testingStudentDocs);

    this.loading = false;
  }

  @action
  async fetchProgramAndLevel(instituteKey, callback) {
    this.loading = true;
    this.batchMembers = [];
    const instituteDoc = await this.ds.instituteRef().get().toPromise();
    const campusDoc = await this.ds.campusRef().get().toPromise();
    const programDoc = await this.ds.instituteProgramRef(instituteKey).get().toPromise();
    const shiftDoc = await this.ds.shiftRef().get().toPromise();
    const levelDoc = await this.ds.levelTestTypeRef().get().toPromise()
    this.level = MappingService.pushToArray(levelDoc);
    this.institutes = MappingService.pushToArray(instituteDoc);
    this.campus = MappingService.pushToArray(campusDoc);
    this.programs = MappingService.orderBy(MappingService.pushToArray(programDoc), "name");
    this.shifts = MappingService.pushToArray(shiftDoc);
    this.selectedInstitute = this.institutes.filter(m => m.key === instituteKey)[0];
    this.selectedShift = this.shifts[0];
    this.selectedCampus = this.campus[0];
    this.loading = false;
    callback(this.institutes)
  }



  @action
  fetchCourses(studentKey, admissionKey, currentTerm) {
    this.process = true;
    this.ds.studentDocument(studentKey).collection<any>("invoices", ref => ref
      .where("program.admissionKey", "==", admissionKey)
      .where("issue_term.key", "==", currentTerm.key)
      .where("isPaid.key", "==", paymentStatus.paid.key)
      // .where("isVoid", "==", false)
      .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
    ).valueChanges().subscribe(docs => {
      this.dataSources = MappingService.orderBy(docs.filter(m => !m.isHeader), "course.schedule_subject.subject.name");
      this.total = docs.filter(m => !m.isHeader && !m.isVoid).length;
      this.process = false;
    })
  }

  @action
  fetchEnglishCourses(studentKey, admissionKey, currentTerm) {
    this.process = true;
    this.englishInvoices = null;
    this.englishInvoiceDetail = null;
    this.ds.studentDocument(studentKey).collection<any>("invoices", ref => ref
      // .where("program.admissionKey", "==", admissionKey)
      .where("issue_term.key", "==", currentTerm.key)
      .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
    ).valueChanges().subscribe(docs => {
      if (docs.length > 0) {
        this.englishInvoices = docs;
        this.englishInvoiceDetail = docs.filter(m => m.isHeader === false);
      }
      this.process = false;
    })
  }

  @action
  fetchStudent(key: string) {
    // this.ds.studentDocument(key).valueChanges().subscribe(doc => {
    //   this.student = doc;
    // })
  }

  @action
  fetchData(schoolKey, field) {
    this.loading = true;
    this.done = false;
    this.ds.studentRef(schoolKey, field).valueChanges().subscribe(docs => {
      if (docs.length) {
        this.lastVisible = docs[docs.length - 1];
      } else {
        this.lastVisible = null;
        this.done = true;
      }
      this.empty = docs.length === 0;
      this.data = docs;
      this.loading = false;
    });
  }


  @action
  onScroll(field, search) {
    if (this.fetching || this.done) return;
    this.fetching = true;
    this.ds
      .studentFetchRef(field, search, this.lastVisible)
      .valueChanges()
      .subscribe(docs => {
        if (docs.length) {
          this.lastVisible = docs[docs.length - 1];
        } else {
          this.done = true;
        }
        this.data = this.data.slice().concat(docs);
        this.fetching = false;
      });
  }

  @action
  search(schoolKey, campusKey, field, search) {
    if (search && search.key) {
      return this.ds.studentSearchRef(schoolKey, campusKey, field, search.puc_id).valueChanges();
    }
    return this.ds.studentSearchRef(schoolKey, campusKey, field, search).valueChanges();
  }

  @action
  async dropCourse(invoiceData: Array<any>, invoice: any, student: any, admissionKey, term: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const { enrollmentKey, key, isPaid, course, amount } = invoice;

    const studentData = await this.ds.studentDocument(student.key).get().toPromise();
    const studentDoc = MappingService.pushToObject(studentData);

    const { prepaid, prepaid_credit, prepaid_subject } = studentDoc;
    const { credit } = course.schedule_subject;
    const total = ConvertService.toNumber(amount);
    const studentPrepaid = this.ds.studentFireRef().doc(student.key).collection("prepaid").doc(enrollmentKey);
    const prepaidRef = this.ds.termFireRef().doc(term.key).collection("prepaid").doc(enrollmentKey);
    const invoiceRef = this.ds.studentFireRef().doc(student.key).collection("invoices").doc(key);
    const transcriptRef = this.ds.transcriptFireRef().doc(student.key).collection("admission").doc(admissionKey).collection("courses").doc(enrollmentKey);
    //void header when last detail

    const paidInvoices = invoiceData.filter(m => m.isVoid === false && m.headerRef === invoice.headerRef);

    if (paidInvoices.length === 2) {
      const headers = paidInvoices.filter(m => m.isHeader);
      if (headers.length > 0) {
        const headerKey = headers[0].key;
        const headerRef = this.ds.studentFireRef().doc(student.key).collection("invoices").doc(headerKey);
        batch.update(headerRef, {
          isVoid: true,
          voidRef: {
            void_by: MappingService.userObj(user),
            void_date: new Date(),
            drop_date_key: ConvertService.toDateKey(new Date()),
            void_type: {
              key: 1,
              text: 'Drop'
            }
          }
        })
      }
    }

    batch.update(this.ds.studentFireRef().doc(student.key), {
      prepaid: ConvertService.toNumber(prepaid) + total,
      prepaid_credit: ConvertService.toNumber(prepaid_credit) + ConvertService.toNumber(credit ? credit : 3),
      prepaid_subject: ConvertService.toNumber(prepaid_subject) + 1,
    })

    batch.update(invoiceRef, {
      isVoid: true,
      voidRef: {
        void_by: MappingService.userObj(user),
        void_date: new Date(),
        drop_date_key: ConvertService.toDateKey(new Date()),
        void_type: {
          key: 1,
          text: 'Drop'
        }
      }
    })
    batch.set(studentPrepaid, {
      ...invoice,
      drop_by: MappingService.userObj(user),
      drop_date: new Date(),
      drop_date_key: ConvertService.toDateKey(new Date()),
      clear_prepaid: false,
    })
    batch.set(prepaidRef, {
      ...invoice,
      drop_by: MappingService.userObj(user),
      drop_date: new Date(),
      drop_date_key: ConvertService.toDateKey(new Date()),
      clear_prepaid: false,
    })
    batch.delete(transcriptRef)

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async dropEnglishCourse(invoices: Array<any>, student: any, term: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const detail = invoices.filter(m => m.isHeader === false)[0];
    const { key, program, amount, isPaid } = detail;
    const studentPrepaid = this.ds.studentFireRef().doc(student.key).collection("prepaid").doc(key);
    const prepaidRef = this.ds.termFireRef().doc(term.key).collection("prepaid").doc(key);
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const batchRef = this.ds.batchLevelFireRef();

    const studentData = await this.ds.studentDocument(student.key).get().toPromise();
    const studentDoc = MappingService.pushToObject(studentData);
    const studentEnrollData = await this.ds.studentDocument(student.key).collection("institute_enrollment").doc(key).get().toPromise();

    const batchData = await this.ds.studentDocument(student.key).collection("student_batch_movement", ref => ref.where("term.key", "==", term.key)).get().toPromise();
    let batchDoc = MappingService.pushToArray(batchData);
    batchDoc = MappingService.orderByDesc(batchDoc, "create_date")

    const { category } = program;
    let oldBatch = null;
    let studentOldBatchDoc: any = null;
    let oldBatchDoc: any = null;
    if (batchDoc && batchDoc.length > 0) {
      oldBatch = batchDoc[0].batchNew;
    }

    if (oldBatch) {
      const oldBatchData = await this.ds.batchLevelRef().doc<any>(oldBatch.key).get().toPromise();
      oldBatchDoc = MappingService.pushToObject(oldBatchData);
      studentOldBatchDoc = await this.ds.batchLevelRef().doc(oldBatch.key)
        .collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();
    }

    let prepaidData = {
      ...detail,
      drop_by: MappingService.userObj(user),
      drop_date: new Date(),
      drop_date_key: ConvertService.toDateKey(new Date()),
      clear_prepaid: false,
      course_status: enrollCourseStatus.drop,
      drop_amount: null,
      is_use_fee: true, // ADD PREPAID
      oldBatch: oldBatchDoc ? oldBatchDoc : ConvertService.toNull(oldBatch),
    }

    let currentBatch = studentDoc[category.key];
    let currentBatchData = currentBatch;
    if (currentBatch && currentBatch.key === oldBatch.key) {
      currentBatchData = null
    }

    if (isPaid.key === paymentStatus.paid.key) {
      prepaidData.drop_amount = amount;
      batch.update(studentRef, {
        [category.key]: currentBatchData,
        drop_by: MappingService.userObj(user),
        drop_date: new Date(),
        prepaid: amount,
        prepaidRef: prepaidData,
      })
    } else {
      batch.update(studentRef, {
        [category.key]: currentBatchData,
        drop_by: MappingService.userObj(user),
        drop_date: new Date(),
      })
    }

    batch.set(studentPrepaid, prepaidData);
    batch.set(prepaidRef, prepaidData);

    invoices.forEach(m => {
      const data = {
        isVoid: true,
        course_status: enrollCourseStatus.drop,
        oldBatch: oldBatchDoc ? oldBatchDoc : ConvertService.toNull(oldBatch),
        voidRef: {
          void_by: MappingService.userObj(user),
          void_date: new Date(),
          drop_date_key: ConvertService.toDateKey(new Date()),
          void_type: {
            key: 1,
            text: 'Drop'
          }
        }
      }
      batch.update(studentRef.collection("invoices").doc(m.key), { ...data })
    })

    if (studentEnrollData.exists) {
      batch.delete(studentRef.collection("institute_enrollment").doc(key));
    }

    if (studentOldBatchDoc && !studentOldBatchDoc.empty) {
      const studentOldBatchData = MappingService.pushToArray(studentOldBatchDoc)[0];
      batch.delete(batchRef.doc(oldBatch.key).collection("students").doc(studentOldBatchData.key));
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async quitEnglishCourse(invoices: Array<any>, student: any, term: any, user: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const detail = invoices.filter(m => m.isHeader === false)[0];
    const { key, program } = detail;
    const studentPrepaid = this.ds.studentFireRef().doc(student.key).collection("prepaid").doc(key);
    const prepaidRef = this.ds.termFireRef().doc(term.key).collection("prepaid").doc(key);
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const batchRef = this.ds.batchLevelFireRef();

    const studentData = await this.ds.studentDocument(student.key).get().toPromise();
    const studentDoc = MappingService.pushToObject(studentData);
    const studentEnrollData = await this.ds.studentDocument(student.key).collection("institute_enrollment").doc(key).get().toPromise();

    const batchData = await this.ds.studentDocument(student.key).collection("student_batch_movement", ref => ref.where("term.key", "==", term.key)).get().toPromise();
    let batchDoc = MappingService.pushToArray(batchData);
    batchDoc = MappingService.orderByDesc(batchDoc, "create_date")

    const { category } = program;
    let oldBatch = null;
    let studentOldBatchDoc: any = null;
    let oldBatchDoc: any = null;
    if (batchDoc && batchDoc.length > 0) {
      oldBatch = batchDoc[0].batchNew;
    }

    if (oldBatch) {
      const oldBatchData = await this.ds.batchLevelRef().doc<any>(oldBatch.key).get().toPromise();
      oldBatchDoc = MappingService.pushToObject(oldBatchData);
      studentOldBatchDoc = await this.ds.batchLevelRef().doc(oldBatch.key)
        .collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();
    }

    let prepaidData = {
      ...detail,
      drop_by: MappingService.userObj(user),
      drop_date: new Date(),
      drop_date_key: ConvertService.toDateKey(new Date()),
      clear_prepaid: false,
      is_use_fee: true, // ADD PREPAID
      course_status: enrollCourseStatus.quit,
      drop_amount: null,
      oldBatch: oldBatchDoc ? oldBatchDoc : ConvertService.toNull(oldBatch),
    }

    const currentBatch = studentDoc[category.key];
    if (currentBatch && currentBatch.key === oldBatch.key) {

      batch.update(studentRef, {
        [category.key]: null,
        drop_by: MappingService.userObj(user),
        drop_date: new Date(),
      })
    }

    batch.set(studentPrepaid, prepaidData);
    batch.set(prepaidRef, prepaidData);

    invoices.forEach(m => {
      const data = {
        isVoid: true,
        course_status: enrollCourseStatus.quit,
        oldBatch: oldBatchDoc ? oldBatchDoc : ConvertService.toNull(oldBatch),
        voidRef: {
          void_by: MappingService.userObj(user),
          void_date: new Date(),
          drop_date_key: ConvertService.toDateKey(new Date()),
          void_type: {
            key: 1,
            text: 'Drop'
          }
        }
      }
      batch.update(studentRef.collection("invoices").doc(m.key), { ...data })
    })

    if (studentEnrollData.exists) {
      batch.delete(studentRef.collection("institute_enrollment").doc(key));
    }

    if (studentOldBatchDoc && !studentOldBatchDoc.empty) {
      const studentOldBatchData = MappingService.pushToArray(studentOldBatchDoc)[0];
      batch.delete(batchRef.doc(oldBatch.key).collection("students").doc(studentOldBatchData.key));
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  // QUIT STUDENT ACADEMIC COURSE
  @action
  async quitAcademicCourse(invoiceDetails: Array<any>, admission, createBy: any, reason: string, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const user = MappingService.userObj(createBy);
    const { student, issue_term, headerRef, isPaid } = invoiceDetails[0];
    const studentRef = this.ds.studentFireRef().doc(student.key);
    const studentOutRef = this.ds.academicQuitStudentsFireRef();
    const transcriptFireRef = this.ds.transcriptFireRef().doc(student.key).collection("admission").doc(admission.key).collection("courses")

    const enrollmentDocs = await this.ds.studentDocument(student.key).collection("enrollment", ref => ref.where("term.key", "==", issue_term.key)).get().toPromise();
    const enrollmentData = MappingService.pushToArray(enrollmentDocs);

    if (enrollmentData && enrollmentData.length > 0) {
      enrollmentData.forEach(e => {
        const data = {
          reason: reason,
          quit_by: user,
          quit_date: new Date(),
          quit_date_key: ConvertService.dateKey(),
          enroll_status: enrollStatusObj.quit,
          isShow: false,
        }
        batch.set(studentOutRef.doc(e.key), e);
        batch.update(transcriptFireRef.doc(e.key), { ...data });
      })
    }

    invoiceDetails.forEach(m => {

      if (isPaid.key === paymentStatus.unpaid.key) {
        batch.delete(studentRef.collection("invoices").doc(m.key));
      }

    })

    if (isPaid.key === paymentStatus.unpaid.key) {
      batch.delete(studentRef.collection("invoices").doc(headerRef));
    }

    batch.commit().then(() => {
      this.process = false;
      callback(true, null);
    })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }

  @action
  async resolveNewStudent(item: any, callback) {
    this.process = true;
    const batch = this.ds.batch();
    const studentBatchRef = this.ds.batchLevelFireRef();
    const { student } = item;

    const comingStudentDoc = await this.ds.instituteNoScheduleStudentRef(student.key).get().toPromise();
    const comingStudentData = MappingService.pushToArray(comingStudentDoc);
    const testingStudentDoc = await this.ds.scheduleInProgressStudentRef(student.key).get().toPromise();
    const testingStudentData = MappingService.pushToArray(testingStudentDoc);

    const studentData = await this.ds.studentDocument(student.key).get().toPromise();;
    const studentDoc = MappingService.pushToObject(studentData);

    const instituteKey = this.selectedInstitute.key;
    const batchData = studentDoc[instituteKey];

    if (batchData) {
      const batchLevelData = await this.ds.batchLevelRef().doc(batchData.key).collection("students", ref => ref.where("student.key", "==", student.key)).get().toPromise();
      const batchLevelDoc = MappingService.pushToArray(batchLevelData);

      if (batchLevelDoc && batchLevelDoc.length > 1) {
        const newEnroll = batchLevelDoc[0];
        batchLevelDoc.forEach(doc => {
          batch.delete(studentBatchRef.doc(batchData.key).collection("students").doc(doc.key));
        })

        batch.set(studentBatchRef.doc(batchData.key).collection("students").doc(newEnroll.key), newEnroll);
      }
    }

    if (comingStudentData && comingStudentData.length > 0) {
      comingStudentData.forEach(m => {
        batch.delete(this.ds.instituteNoScheduleStudentFireRef().doc(m.key));
      })
    }
    if (testingStudentData && testingStudentData.length > 0) {
      testingStudentData.forEach(m => {
        batch.delete(this.ds.scheduleInProgressFireRef().doc(m.key));
      })
    }

    batch.commit()
      .then(() => {
        this.process = false;
        callback(true, null);
      })
      .catch(error => {
        this.process = false;
        callback(false, error);
      });
  }


}
